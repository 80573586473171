import React, { useEffect, useState, useContext} from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import Sidebar from '../../components/Shared/Sidebar';
import APIServicenew from '../../utils/APIGeneralService';
import DefaultImage from '../../imgs/default.jpg';
import { FaEdit, FaTrash ,FaArrowLeft } from 'react-icons/fa';
import MasterGodForm from '../../components/MasterGod/MasterGodForm';
import { capitalizeFirstLetter, objectToFormData ,getCookies,setCookies} from '../../utils/Helper';
import GodImagesForm from '../../components/GodImages/GodImagesForm';
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate ,useLocation } from "react-router-dom";

const GodImages = () => {
  const APIService = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const token = getCookies('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = getCookies('astroUserId');
  const ITEMS_PER_PAGE = 8;

  document.title = 'God Images';
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const godName = searchParams.get('godName');
  const language = searchParams.get('language');
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [allGodImages, setAllGodImages] = useState([]);
  const [godList, setGodList] = useState([]);
  const [itemsOffset, setItemsOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [contentType, setContentType] = useState(language??'english');
  const [selectedGodId, setSelectedGodId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGodImageForm, setShowGodImageForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const[loader,setLoader]=useState(false)

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  useEffect(() => {
    if (!selectedGodId) return;
    getAllGodImages();
  }, [itemsOffset, selectedGodId]);

  useEffect(() => {
    getGodList();
  }, [contentType]);

  const getAllGodImages = async () => {
    setLoading(true);
    const res = await APIService.get(
      `godImage/getGodImageList/${itemsOffset}/${ITEMS_PER_PAGE}/${selectedGodId}`,
      headers
    );
    let data = res?.response?.data
    if (res && res.data && res.data.status === true) {
      setAllGodImages(res?.data?.Listdata);
      setTotalCount(res?.data?.totalCount);
    }else{
      if(data?.status == 4){
        logout()
       }
    }
    setLoading(false);
  };

  const getGodList = async () => {
    setLoading(true);
    const res = await APIService.get(`master/getGodList/${contentType}`, headers);
    let data = res?.response?.data
   
    if (res && res.data && res.data.status === true) {
      setGodList(res?.data?.Listdata);
      if (res?.data?.Listdata?.length > 0) {
         const matchedGod = res.data.Listdata.find(item => item.godName === godName);
         if (matchedGod) {
           setSelectedGodId(matchedGod._id);
         } else {
           setSelectedGodId(res?.data?.Listdata?.[0]?._id);
         }
      }
    }else{

      if(data?.status == 4){
        logout()
       }
    }
    setLoading(false);
  };

  const handlePaginationClick = e => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * ITEMS_PER_PAGE;
    setItemsOffset(newOffset);
  };

  const handleModalClose = () => {
    setShowDeleteModal(false);
    setShowGodImageForm(false);
    setSelectedItem(null);
  };

  const handleDelete = async () => {
    try {
      if (!selectedItem) return;
      const id = selectedItem._id;

      const deleteRes = await APIService.delete(`godImage/findByIdAndremove/${id}`, headers);

      if (deleteRes && deleteRes.status === 200) {
        toast.success(deleteRes?.data?.message);
        setItemsOffset(0)
        await getAllGodImages();
      } else {
        toast.error(deleteRes?.data?.message ? deleteRes?.data?.message : 'Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message ? error?.message : 'Something went wrong');
    } finally {
      setSelectedItem(null);
      setShowDeleteModal(false);
    }
  };

  const handleclick = async (status, id) => {
    var datas = status === 1 ? 2 : 1;
    const formData = new FormData();
    formData.append("status", datas);
    await APIService.put(`godImage/updateStatus/${id}`, formData, headers)
      .then((res) => {
        getAllGodImages();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFormSubmit = async data => {
    try {
      const payload = {
        image: data?.godImage,
        godId: data?.godInfo?.value,
        userId: astroUserId,
      };
      let formdata = objectToFormData(payload);
      let res;
      if (!selectedItem) {
        setLoader(true)
        res = await APIService.post('godImage/addGodImage', formdata, {
          ...headers,
          'content-type': 'multipart/form-data',
        });
      } else {
        setLoader(true)
        res = await APIService.post(`godImage/updateGodImage/${selectedItem._id}`, formdata, {
          ...headers,
          'content-type': 'multipart/form-data',
        });
      }
      if (res && res.status === 200 && res.data?.status === true) {
        toast.success(res?.data?.message);
        setLoader(false)
        handleModalClose();
        await getAllGodImages();
      } else {
        toast.error(res?.data?.message ? res?.data?.message : 'Something went wrong');
        setLoader(false)
      }
    } catch (error) {
      toast.error(error?.message ? error?.message : 'Something went wrong');
      setLoader(false)
    } finally {
     
      setLoader(false)
      setSelectedItem(null);
    }
  };

  return (
    <>
      <div className='main aarti_section master_god'>
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>
            <FaArrowLeft
                 className="hd-cr "
                 style={{marginRight: "15px"}}
                 size={25}
                 onClick={() => navigate(-1)}/>
              God Images <small>({totalCount})</small></h4>
            <div className='_btn'>
              <Button
                variant='primary'
                onClick={() => {
                  setShowGodImageForm(true);
                }}
                className='by-btn as_btn me-0'
              >
                Add Image
              </Button>
            </div>
          </div>
        </div>
        <div className='aarti_inner pt-5'>
          <div className='container'>
            <div className='row'>
              {/* <div className='col-md-4 col-lg-3'>
                <Sidebar />
              </div> */}
              <div className='col-md-8 col-lg-12'>
                {loading ? (
                  <div className='center'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <>
                    <div className='row'>
                      <div className='container'>
                        <div className='d-flex align-items-center justify-content-end'>
                          <Form.Select
                            aria-label='godList'
                            className=' w-25 astroWatch-select-hover me-2 hd-cr'
                            value={selectedGodId}
                            onChange={e => {
                              setSelectedGodId(e.target.value);
                              setItemsOffset(0);
                            }}
                          >
                            {godList &&
                              godList.length > 0 &&
                              godList.map(god => {
                                return (
                                  <option key={god?._id} value={god?._id}>
                                    {capitalizeFirstLetter(god?.godName)}
                                  </option>
                                );
                              })}
                          </Form.Select>
                          <Form.Select
                            aria-label='categories'
                            className=' w-25 astroWatch-select-hover hd-cr'
                            value={contentType}
                            onChange={e => {
                              setContentType(e.target.value);
                              setItemsOffset(0);
                            }}
                          >
                            <option value='hindi'>Hindi</option>
                            <option value='english'>English</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                    
                    <div className='row mt-3'>
                      {allGodImages && allGodImages.length > 0 ? (
                        allGodImages?.map(item => (
                          <div className='col-md-6 col-lg-3'>
                            <div className='card mb-4' key={item?._id}>
                              <div className='card-body ' style={{ minHeight: '90px' }}>
                                <div className=''>
                                  <img
                                    
                                    src={item?.image ? `${item?.image}` : DefaultImage}
                                    className='god-card-image'
                                    alt='img'
                                  />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <div className='d-flex align-items-center mt-2'>
                                    <h3 className='heading_18 mb-0'>{item.godName}</h3>
                                  </div>
                                  <div className='icon' style={{display:"flex"}}>
                                    <FaEdit
                                      className='me-2 hd-cr'
                                      size={18}
                                      onClick={e => {
                                        setShowGodImageForm(true);
                                        setSelectedItem(item);
                                      }}
                                    />
                                    <FaTrash
                                      className='dt-btn hd-cr me-1'
                                      size={18}
                                      onClick={e => {
                                        setShowDeleteModal(true);
                                        setSelectedItem(item);
                                      }}
                                    />
                                    {item.status === 1 ? (
                                      <input
                                        type="button"
                                        className="_active me-1"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Active"
                                        onClick={() =>
                                          handleclick(item.status, item._id)
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="button"
                                        className="_inactive"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Inactive"
                                        onClick={() =>
                                          handleclick(item.status, item._id)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className='mt-5 d-flex justify-content-center align-items-center'>
                          <h4>No Images Found...</h4>
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div className='pagination'>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next>'
                    onPageChange={handlePaginationClick}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(totalCount / ITEMS_PER_PAGE)}
                    previousLabel='< Previous'
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={itemsOffset / ITEMS_PER_PAGE}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showGodImageForm && (
        <GodImagesForm
          show={showGodImageForm}
          handleClose={() => handleModalClose()}
          onSubmit={handleFormSubmit}
          selectedItem={selectedItem}
          godList={godList}
          loader={loader}
        />
      )}
      <Modal
      centered
        show={showDeleteModal}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleModalClose}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default GodImages;
