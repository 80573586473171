import React, { createRef, useEffect, useRef, useMemo, useState ,useCallback } from 'react';
import { Col, Container, Row, Button, InputGroup, FormControl, Spinner, Table, Form } from 'react-bootstrap';
import Select from 'react-select';
import { Pencil, XOctagon, XLg } from 'react-bootstrap-icons';
import { Fa, FaSistrix } from 'react-icons/fa';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { useToasts } from 'react-toast-notifications';
import APIServicenew from '../utils/APIGeneralService';
import { getCookies } from '../utils/Helper';
import ReactPaginate from 'react-paginate';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DynamicDataTableWithCount from './DynamicDataTableWithCount';

export const ConnectAstroUser = () => {
  document.title = 'ConnectAstroCustomer';

  const defaultState = {
    astroContactNumber: '',
    userContactNumber: '',
    phoneCode: '+91',
  };
  const { addToast } = useToasts();
  const [newData, setNewData] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [list, setList] = useState([]);
  const [state, setState] = useState(defaultState);
  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [forid, setForid] = useState(false);
  const [callStatus,setCallStatus] = useState('')
  const [search , setSearch] = useState('')
  const [date , setDate] = useState('')
  const [timeLimit, setTimeLimit] = useState('');
  const [offset, setOffset] = useState(0);
  const [perPage ,setPerPage] = useState(20);
  const [allDataCount, setAllDataCount] = useState();
  const [pageCount,setPageCount] = useState('')
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const { userData, astrologerData } = newData;
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [ flag ,setFlag ] = useState(false)

  const statusMap = {
    1: { text: 'In-progress', testClass: 'text-warning fw-bold' },
    2: { text: 'Confirm', testClass: 'text-success fw-bold' },
    3: { text: 'Initiate', testClass: 'text-pending fw-bold' },
    4: { text: 'Completed', testClass: 'text-success fw-bold' },
    5: { text: 'Failed', testClass: 'text-danger font_14 fw-bold' },
    6: { text: 'Reject', testClass: 'text-danger font_14 fw-bold' },
    7: { text: 'Cancelled', testClass: 'text-danger font_14 fw-bold' },
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    
   
    {
      Header: 'Astrologer Name / Contact Number',
      Cell: e => {
        return <span>{`${e.row.original?.astrologerName ?? '_'} / ${e.row.original?.astrologerContactNumber ?? '_'}`}</span>;
        
      },
    },
    {
      Header: 'Customer Name / Contact Number',
      Cell: e => {
        return <span>{`${e.row.original?.userName ?? '_'} / ${e.row.original?.userContactNumber ?? '_'}`}</span>;
      },
    },
    {
      Header: 'Plivo Number',
      Cell: e => {
        return <span>{e.row.original.plivoNumber ?? '_'}</span>;
      },
    },
    {
      Header: 'Call Status',
      Cell: e => {
        return  <span className={statusMap[e.row.original.callStatus]?.testClass} >
                     {statusMap[e.row.original.callStatus]?.text}
                </span>;
      },
    },
    {
      Header: 'Call Type',
      Cell: e => {
        return <span>{e.row.original.callType ?? '_'}</span>;
      },
    },
    {
      Header: 'Time Limit',
      Cell: e => {
        return <span>{e.row.original.timeLimit ?? '_'}</span>;
      },
    },
    {
      Header: 'Duration',
      Cell: e => {
        return <span>{e.row.original.duration ?? '_'}</span>;
      },
    },
  
    {
      Header: 'Date',
      Cell: e => {
        return (
          <>
            <span>{e.row.original.createdAt ? moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A') : '_'}</span>
          </>
        );
      },
    },
    
   
  ]);

  useEffect(() => {
    getHistoryList(skip, limit, pageIndex);
  }, [flag]);

  const getHistoryList = useCallback(
    async (skip, limit, index) => {
      try {
        const response = await APIServices.get(`marketingCall/historyList/${skip}/${limit}?searchKey=${search}&date=${date}&callStatus=${callStatus}`);
       
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.total_count / limit));
          setAllDataCount(response.data?.total_count)
          setList(response.data?.data);
          setFlag(false)
          window.scrollTo({ top: 0 });
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, search ,date,callStatus]
  );

  const handleFilterClick = () => {
    if (search || date || callStatus) {
      getHistoryList(skip, limit, 0, search ,date,callStatus);
    }
  };

  const handleStartDate = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setDate(formattedDate);
  };


  function handleChange(e) {
    let key, value;

    key = e?.target?.name;

    if (!!errors[key]) {
      setErrors({
        ...errors,
        [key]: null,
      });
    }
  }

  const validateInputs = () => {
    let isValid = false;

    const newErrors = {};

    if (state.astroContactNumber == '') newErrors.astroContactNumber = 'Please enter astrologer contact number .';
    else if (state.astroContactNumber.length > 10 || state.astroContactNumber.length < 10)
      newErrors.astroContactNumber = 'Astrologer contact number cannot be less then 10 digits and more then 10 digits!';

    if (state.userContactNumber == '') newErrors.userContactNumber = 'Please enter customer contact number .';
    else if (state.userContactNumber.length > 10 || state.userContactNumber.length < 10)
      newErrors.userContactNumber = 'Customer contact number cannot be less then 10 digits and more then 10 digits!';

    if (state.phoneCode == '') newErrors.phoneCode = 'Please select phone code .';
    return newErrors;
  };

  const handleGetDetails = async event => {
    event.preventDefault();
    const token = getCookies('accessToken');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let payload = {};
    payload.astroContactNumber = state.astroContactNumber;
    payload.userContactNumber = state.userContactNumber;
    payload.phoneCode = state.phoneCode;

    const newErrors = validateInputs();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      const res = await APIServices.post('marketingCall/searchAstroUser', payload, headers);
      if (res && res.status === 200) {
        let apiData = res?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          setNewData(apiData);
          setErrors({});
        } else {
          setNewData([]);
          toast.error(apiData?.message);
        }
      }
    }
  };
  const handleCall = async event => {
    event.preventDefault();
    const token = getCookies('accessToken');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let payload = {};
    payload.astrologerId = astrologerData?._id;
    payload.userId = userData?._id;
    payload.timeLimit = parseInt(timeLimit);
    setLoader(true);
    const res = await APIServices.post('marketingCall/callAstroUser', payload, headers);
    if (res && res.status === 200) {
      let apiData = res?.data;
      if (apiData?.status === true) {
        toast.success(apiData?.message);
        setLoader(false);
        getHistoryList(skip ,limit);
        setState(defaultState);
        setNewData([]);
        setTimeLimit('');
      } else {
        toast.error(apiData?.message);
        setLoader(false);
      }
    }
  };

  const handleReset = () => {
    setSearch('')
    setCallStatus('')
    setDate('')
    setOffset(0)
    setFlag(true)
  }
  
  const timeLimitSelect = () => {
    return (
      <>
        <Form.Group as={Col} size='md' className='' controlId='validationCustom01'>
          <Form.Label className='required'>Time Limit</Form.Label>
          <Form.Select
            aria-label='Default select example'
            required
            className='hd-cr'
            value={timeLimit}
            onChange={e => setTimeLimit(e.target.value)}
          >
            <option value=''>Choose</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={12}>12</option>
            <option value={13}>13</option>
            <option value={14}>14</option>
            <option value={15}>15</option>
          </Form.Select>
        </Form.Group>
      </>
    );
  };
  const renderAstroMobile = () => {
    return (
      <>
        <Form.Group as={Col} size='md' controlId='validationCustom01'>
          <Form.Label className='required'> Astrologer Contact Number</Form.Label>
          <Form.Control
            required
            name='astroContactNumber'
            type='text'
            placeholder='Number'
            isInvalid={!!errors?.astroContactNumber}
            value={state.astroContactNumber}
            onChange={e => {
              setState({ ...state, astroContactNumber: e.target.value });
              handleChange(e);
            }}
            onKeyPress={(evnt) => {
              // Allow only numeric characters
              if (!/^\d$/.test(evnt.key)) {
                evnt.preventDefault();
              }
            }}
          />
          <Form.Control.Feedback type='invalid'>{errors?.astroContactNumber}</Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };
  const phoneCode = () => {
    return (
      <>
        <Form.Group className='phn-code' size='md' controlId='validationCustom01'>
          {/* <Form.Label className='required'></Form.Label> */}
          <Form.Select
            aria-label='Default select example'
            required
            className='hd-cr'
            style={{ height: '40px', border: '1px solid #b3b3b3' }}
            isInvalid={!!errors?.phoneCode}
            name='phoneCode'
            value={state?.phoneCode}
            onChange={e => {
              setState({ ...state, phoneCode: e.target.value });
              handleChange(e);
            }}
          >
            {/* <option value=''>Choose</option> */}
            <option value='+91'>+91</option>
            <option value='+1'>+1</option>
          </Form.Select>
          <Form.Control.Feedback type='invalid'>{errors?.phoneCode}</Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };
  const renderUserMobile = () => {
    return (
      <>
        <Form.Group className='phn-num' size='md' controlId='validationCustom01'>
          {/* <Form.Label className='required'> User Contact Number</Form.Label> */}
          <Form.Control
            required
            name='userContactNumber'
            type='text'
            placeholder='Number'
            isInvalid={!!errors?.userContactNumber}
            value={state.userContactNumber}
            onChange={e => {
              setState({ ...state, userContactNumber: e.target.value });
              handleChange(e);
            }}
            onKeyPress={(evnt) => {
              // Allow only numeric characters
              if (!/^\d$/.test(evnt.key)) {
                evnt.preventDefault();
              }
            }}
          />
          <Form.Control.Feedback type='invalid'>{errors?.userContactNumber}</Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };
  return (
    <>
      {' '}
      <div
        className='notifiction_area'
        style={{
          backgroundImage: 'url("/images/why_choose_us.jpg")',
        }}
      >
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>
              <Row>
                <Col>
                  <h5>
                    Connect Astro - Customer <small>({allDataCount})</small>
                  </h5>
                </Col>
              </Row>
            </h4>
          </div>
        </div>
        <div className='container-fluid'>
          <div className='notifiction_form box-shadow br-6 p-20 my-3'>
            <Form>
              <Row className='mx-0'>
                <Col className='my-2 col-12 col-md-4'>{renderAstroMobile()}</Col>
                {/* <Col className='my-2 col-12 col-md-2'>{phoneCode()}</Col> */}
                <Col className='my-2 col-12 col-md-5'>
                <Form.Label className='required'> Customer Contact Number</Form.Label>

                <Row className='code-num'>
                   {phoneCode()}{renderUserMobile()}
                </Row>
                   </Col>
                <Col className='my-2 col-12 col-md-3'>
                  <Button
                    variant='primary'
                    className='as_btn'
                    style={{ marginTop: '34px' }}
                    onClick={e => handleGetDetails(e)}
                  >
                    Verify Details
                  </Button>
                </Col>
              </Row>
              {astrologerData && userData ? (
              <>
              <Row className='mt-3'>
                  <>
                    <div className='col-sm-4'>
                      <div className='card'>
                        <div className='card-body'>
                          <h5 className='card-title text-start font_18'>Astrologer Details</h5>
                          <div className='column-container'>
                            <div className='list-column'>
                              <div className='list-group-item font_16'>Name </div>
                              <div className='list-group-item font_16'>Email</div>
                              {/* <div className='list-group-item'>Profile</div> */}
                            </div>
                            <div className='list-column'>
                              <div className='list-group-item font_16'>{astrologerData?.name ?? null}</div>
                              <div className='list-group-item font_16'>{astrologerData?.email ?? null}</div>
                              {/* <div className='list-group-item'>
                                <img
                                  // crossOrigin="anonymous"
                                  className='imgs'
                                  src={astrologerData?.profileImage}
                                  width='50%'
                                  alt=''
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='card'>
                        <div className='card-body'>
                          <h5 className='card-title text-start font_18'>Customer Details</h5>
                          <div className='column-container'>
                            <div className='list-column'>
                              <div className='list-group-item font_16'>Name </div>
                              <div className='list-group-item font_16'>Email</div>
                              {/* <div className='list-group-item'>Profile</div> */}
                            </div>
                            <div className='list-column'>
                              <div className='list-group-item font_16'>{userData?.name ?? null}</div>
                              <div className='list-group-item font_16'>{userData?.email ?? null}</div>
                              {/* <div className='list-group-item'>
                                <img
                                  // crossOrigin="anonymous"
                                  className='imgs'
                                  src={userData?.profileImage}
                                  width='50%'
                                  alt=''
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-4'>{timeLimitSelect()}</div>
                  </>
               
              </Row>
              <Row>
                <Col className='text-center m-3 '>
                  {loader ? (
                    <Button variant='primary' className='as_btn'>
                      Processing...
                    </Button>
                  ) : (
                    <Button
                      variant='primary'
                      className='as_btn'
                      disabled={!userData || !astrologerData}
                      onClick={e => {
                        handleCall(e);
                      }}
                    >
                      Call Now
                    </Button>
                  )}
                </Col>
              </Row>
              </>
               ) : (
                ''
              )}
            </Form>
          </div>
        </div>

          <div className='container-fluid bg-white py-3'>
              <div className='d-flex flex-wrap gap-3'>
                <div className='chat-search-field w-25'>
                      <span className='chat-bot-search-icon'>
                      {/* <i className='fa fa-search'></i> */}
                      <FaSistrix />
                    </span>
                  <input
                  type='number'
                  className='form-control form-input w-100'
                  placeholder='Search by contact no.'
                  onChange={ (e) => setSearch(e.target.value)}
                  value={search}
                  onKeyDown={(e) => {
                    if (e.target.value.length >= 10 && e.keyCode !== 8 && e.keyCode !== 46) {
                      e.preventDefault();
                    }
                  }}
                />
                </div>
     
           
                {/* <input
                  className=' form-control  w-25 mx-2'
                  name='startdate'
                  type='date'
                  style={{height:'36px'}}
                  value={date}
                  onChange={(e) => {setDate(e.target.value);setOffset(0)}}
                /> */}
                 <DatePicker
                        placeholderText={"Select a date"}
                        className="form-control form-ch fil-date-pick"
                        name="date"
                        selected={date ? new Date(date) : null}
                        onChange={(date) => {
                          handleStartDate(date);
                          setOffset(0);
                        }}
                        dateFormat="yyyy-MM-dd"
                  />
          
        <Form.Select
              aria-label="categories"
              className=" w-25 astroWatch-select-hover hd-cr right-q mb-2"
              value={callStatus}
              onChange={(e) => {setCallStatus(e.target.value);setOffset(0)}}
            >
              <option value="">Select call Status</option>
              <option value="1">In-progress</option>
              <option value="2">Confirm</option>
              <option value="3">Initiate</option>
              <option value="4">Completed</option>
              <option value="5">Failed</option>
              <option value="6">Reject</option>
              <option value="7">Cancelled</option>

         </Form.Select>
         <Button className='as_btn ms-0' style={{marginTop:"2px"}} variant='primary' onClick={handleFilterClick}>
                Filter
         </Button>

         <Button className='as_btn ms-0 reset-btn' style={{marginTop:"2px"}} variant='primary' onClick={handleReset}>
                Reset
          </Button>

       
              </div>
            </div>

       {list && list.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={list}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={getHistoryList}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className='no_records'>Records not Available</h2>
        )}

      </div>
      <ToastContainer />
    </>
  );
};

export default ConnectAstroUser;
