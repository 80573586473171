import React, { useEffect, useState, useContext } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaArrowLeft, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
import { formateDateAndTime, SlugifyStringWithDash, capitalizeFirstLetter, setCookies } from '../../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../../context/UserContextProvider';

const AddEditPandit = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editDataId = searchParams.get('editDataId');
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [inputFields, setInputFields] = useState({
    name: '',
    address:'',
    mobileNumber: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const handleChange = e => {
    const { name, value } = e.target;

    setInputFields(prev => ({ ...prev, [name]: value }));

    if (!!errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!inputFields?.name || inputFields?.name.trim() === '') {
      newErrors.name = 'Please enter Name.';
    }
    if (!inputFields?.address || inputFields?.address.trim() === '') {
      newErrors.address = 'Please enter Address.';
    }

    if (!inputFields?.mobileNumber || inputFields?.mobileNumber.trim() === '') {
      newErrors.mobileNumber = 'Please enter Mobile Number.';
    } else if (inputFields?.mobileNumber?.length !== 10) {
      newErrors.mobileNumber = 'Mobile number length must be 10.';
    }
    
    return newErrors;
  };

  const handleReset = () => {
    setInputFields({
      name: '',
      address:'',
      mobileNumber: '',
    })
    setErrors({})
    }

    const showEditData = item => {
      setSelectedId(item?._id);
      setInputFields({
        name: item?.name,
        address: item?.address,
        mobileNumber: item?.contactNumber,
      });
    }

    useEffect(() => {
      if (editDataId) {
        showEditData();
      }
    }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    const newErrors = validateInputs();
    if (Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    setLoading(true);
    const formData = new FormData();

    formData.append('name', inputFields?.name);
    formData.append('address', inputFields.address);
    formData.append('contactNumber', inputFields?.mobileNumber);
    // handleReset()
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    try {
      const response = await APIServices.post('pandit/addpandit', formData,headers);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setLoading(false);
        handleReset();
        Navigate("/pujaPandit")
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    getEditData();
  }, [editDataId]);

  const getEditData = async () => {
    if (editDataId) {
      let res = await APIServices.get(`pandit/getPanditById/${editDataId}`, headers);
      if (res?.status === 200) {
        let apiData = res?.data;
        if (apiData?.status) {
          showEditData(apiData?.data);
        }
      }
    }
  };

  const handleUpdate = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', inputFields?.name);
    formData.append('address', inputFields.address);
    formData.append('contactNumber', inputFields?.mobileNumber);


    const newErrors = validateInputs();
    setLoading(true);
    if (Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors);
      setLoading(false)
    } else {
      const response = await APIServices.post(`pandit/updatePanditById/${editDataId}`, formData, headers);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setLoading(false);
        Navigate("/pujaPandit")
      } else {
        toast.error(response?.data?.message);
      }
    }
  };
  
  return (
    <div>
      <>
        <div className='main aarti_section'>
          <>
            <div className='gita_quote_heading'>
              <div className='container d-flex align-items-center justify-content-between'>
                <h4 className='hd-list-f left-q'>
                  <FaArrowLeft
                    className='hd-cr '
                    style={{ marginRight: '15px' }}
                    size={25}
                    onClick={() => Navigate(-1)}
                  />

                  { selectedId ? 'Edit' : 'Add'} Pandit
                </h4>
              </div>
            </div>
          </>
        </div>
      </>

      <div className='container'>
        <div className='notifiction_form p-0'>
          <Form
              className='add_blog_form box-shadow p-20 br-6'
              onSubmit={e => `${selectedId ? handleUpdate(e) : handleSubmit(e)}`}
            >
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='4'>
                Name
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Name...'
                  name='name'
                  value={inputFields?.name}
                  isInvalid={!!errors.name}
                  className='form-control '
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId=''>
              <Form.Label className='required' column sm='4'>
                Address
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  name='address'
                  value={inputFields?.address}
                  isInvalid={!!errors?.address}
                  placeholder='Address...'
                  className='form-control '
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors?.address}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='mobileNumber'>
              <Form.Label className='required' column sm='4'>
                Mobile Number
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='number'
                  name='mobileNumber'
                  value={inputFields?.mobileNumber}
                  isInvalid={!!errors?.mobileNumber}
                  placeholder='Mobile Number...'
                  className='form-control'
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors?.mobileNumber}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Row className='justify-content-center d-flex'>
              <Button
                variant='primary'
                className='as_btn'
                type='submit'
                // onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}
                // onClick={(e) => handleSubmit(e)}
              >
                 {loading ? 'Loading...' : `${editDataId ? 'Edit' : 'Add'}`}
              </Button>
              {selectedId ? "" : 
              (
              <Button
                variant='secondary'
                className='as_btn reset-btn'
                  onClick={handleReset}
              >
                Reset
              </Button>
              )}
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditPandit;
