import React, { useEffect, useState ,useMemo ,useContext} from 'react';
import { Button, Form, Modal, Spinner ,Row, Col } from 'react-bootstrap';
import { Pencil, XOctagon ,XLg} from "react-bootstrap-icons";
import ReactPaginate from 'react-paginate';
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import Sidebar from '../../components/Shared/Sidebar';
import APIServicenew from '../../utils/APIGeneralService';
import DefaultImage from '../../imgs/default.jpg';
import { FaEdit, FaTrash } from 'react-icons/fa';
import MasterGodForm from '../../components/MasterGod/MasterGodForm';
import { objectToFormData, objectToFormData1,capitalizeFirstLetter,getCookies,setCookies } from '../../utils/Helper';
import moment from 'moment';

const MasterGod = () => {
  const APIService = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const token = getCookies('accessToken')
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = getCookies('astroUserId');
  const ITEMS_PER_PAGE = 4;

  document.title = 'Mandir God';
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allGods, setAllGods] = useState([]);
  const [godList, setGodList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [selectedGodId, setSelectedGodId] = useState('');
  const [selectedGodName, setSelectedGodName] = useState('');
  const [startTime ,setStartTime] = useState('');
  const [endTime ,setEndTime] = useState('');
  const [day , setDay] = useState('')
  const [itemsOffset, setItemsOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [contentType, setContentType] = useState('english');
  const [showDrop, setShowDrop] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMasterGodModal, setShowMasterGodModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const[loader,setLoader]=useState(false)
  const [ flag ,setFlag] = useState(false)
  const [ aartiList ,setAartiList] = useState()
  const [progress ,setProgress] = useState(0)

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }

  useEffect(() => {
    getAllGods();
  }, [contentType, itemsOffset]);
 
    useEffect(() => {
    getAartiList();
  }, [contentType]);

  const getAartiList = async () => {
    setLoading(true);
    const res = await APIService.get(`astroAarti/getAll/${contentType}`, headers);
    let data = res?.response?.data

    if (res && res.data && res.data.status === true) {
      if (res?.data?.Listdata?.length > 0) {
        setAartiList(res?.data?.Listdata);

      }
    }else{
      if(data?.status == 4){
        logout()
       }
      }
    setLoading(false);
  };

  const getAllGods = async () => {
    setLoading(true);
    const res = await APIService.get(`master/getGodList/${contentType}/${itemsOffset}/${ITEMS_PER_PAGE}`, headers);
    let data = res?.response?.data
  
    if (res && res.data && res.data.status === true) {
      setAllGods(res?.data?.Listdata);
      setTotalCount(res?.data?.totalCount);
    }else{
      if(data?.status == 4){
        logout()
       }
      }
    setLoading(false);
  };

  const getGodList = async () => {
    setLoading(true);
    const res = await APIService.get(`master/getGodList/${contentType}`, headers);
    let data = res?.response?.data
   
    if (res && res.data && res.data.status === true) {
      setGodList(res?.data?.Listdata);
    }else{
      if(data?.status == 4){
        logout()
       }
      }
    setLoading(false);
  };

  useEffect(() => {
    getGodList();
  }, [contentType]);

  const handlePaginationClick = e => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * ITEMS_PER_PAGE;
    setItemsOffset(newOffset);
  };

  const handleCloseDrop = () => {
    setFlag(false)
    setSelectedGodId('')
    setSelectedGodName('')
    setStartTime('')
    setEndTime('')
    setShowDrop(false);}

  const handleDrop = (e) =>{
     setShowDrop(true);
   }
  
  const handleDropUpdate = (id ,godName ,godId ,startTime ,endTime ,day ) =>{
      setSelectedId(id)
      setSelectedGodName(godName)
      setSelectedGodId(godId)
      setStartTime(startTime)
      setEndTime(endTime)
      setDay(day)
 }

  const handleModalClose = () => {
    setShowDeleteModal(false);
    setShowMasterGodModal(false);
    setSelectedItem(null);
  };

  const handleDelete = async () => {
    try {
      if (!selectedItem) return;
      const id = selectedItem._id;
      const deleteRes = await APIService.delete(`master/findByIdAndremove/${id}`, headers);

      if (deleteRes && deleteRes.status === 200) {
        toast.success(deleteRes?.data?.message);
        setItemsOffset(0)
        await getAllGods();
      } else {
        toast.error(deleteRes?.data?.message ? deleteRes?.data?.message : 'Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message ? error?.message : 'Something went wrong');
    } finally {
      setSelectedItem(null);
      setShowDeleteModal(false);
    }
  };

  const handleclick = async (status, id) => {
    var datas = status === 1 ? 2 : 1;
    const formData = new FormData();
    formData.append("status", datas);
    await APIService.put(`master/updateStatus/${id}`, formData, headers)
      .then((res) => {
        getAllGods();
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const handleFormSubmit = async data => {
    try {
      let payload = {}, formdata = {};

      if(selectedItem?._id){
        payload = {
          ...data,
          day: data.day.value ?? null,
          contentEn: contentType === 'english',
          contentHi: contentType === 'hindi',
          aartiId:data.aarti === 'yes'?data.aartiId.value:null,
          userId: astroUserId,
        };
        formdata = objectToFormData1(payload);
      }else{
        payload = {
          ...data,
          day: data.day?.value,
          contentEn: contentType === 'english',
          contentHi: contentType === 'hindi',
          aartiId:data.aarti === 'yes'?data.aartiId.value:null,
          userId: astroUserId,
        };
        formdata = objectToFormData(payload);
      }

     let res;
     //  return
     if (!selectedItem) {
      setLoader(true)
          if( typeof payload?.ABCGodImage==="object" && typeof payload?.mandirGodImage==="object"){
            res = await APIService.post('master/addGod', formdata, { ...headers, 'content-type': 'multipart/form-data' },(progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percentCompleted)
          });
          }else{
                toast.error("Please select a image to upload ");
                setLoader(false)
              }
          } else {
            setLoader(true)
              res = await APIService.post(`master/updateGodData/${selectedItem._id}`, formdata, {
                  ...headers,
                  'content-type': 'multipart/form-data',
                },(progressEvent) => {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  setProgress(percentCompleted)
              });
              }
              if (res && res.status === 200 && res.data?.status === true) {
                toast.success(res?.data?.message);
                setSelectedItem(null);
                handleModalClose();
                setLoader(false)
                await getAllGods();
              } else {
                toast.error(res?.data?.message ? res?.data?.message : 'Something went wrong');
              }
                } catch (error) {
      toast.error(error?.message ? error?.message : 'Something went wrong');
      setLoader(false)
    } finally {
      setLoader(false)
      // setSelectedItem(null);
    }
  };

  const fetchProfilesByPagination = async () => {
    const res = await APIService.get(`daywisegodpreference/list/${contentType}`, headers);
    if (res && res.data && res.data.status === true) {
      if (res?.data?.Listdata?.length > 0) {
        setTableData(res?.data?.Listdata);
      }
    }
  }

  const handleActiveClick = async (skip, limit, index, status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append('status', datas);
    await APIService.put(`daywisegodpreference/updateStatus/${id}`, formData, headers)
      .then(res => {
        fetchProfilesByPagination();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleEdit = async () =>{
    const formData = new FormData();
    formData.append("godName", selectedGodName);
    formData.append("godId", selectedGodId);
    formData.append("startTime",startTime );
    formData.append("endTime", endTime);
    let response = await APIService.put(
      `daywisegodpreference/editOne/${selectedId}`,
      formData,
      headers
    );
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status === true) {
        toast.success(apiData?.message);
        fetchProfilesByPagination()
        setFlag(false)
      }
  }
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Day',
        Cell: (e) => {
				return <span>{capitalizeFirstLetter(e.row.original.day)}</span>
		  }
      },
      {
        Header: 'God',
        Cell: (e) => {		
				return <span>{e.row.original.godName}</span>
		  }
      },
      {
        Header: 'Start Time',
        Cell: (e) => {
				return <span>{e.row.original.startTime}</span> 
		  }
      },
      {
        Header: 'End TIme',
        Cell: (e) => {
				return  <span>{e.row.original.endTime}</span>

		  }
      },
      {
        Header: 'Action',
        Cell: e => {
          if (e.row.original.status === true) {
            return (
              <h5>
                <input
                  type='button'
                  className='_active'
                  value='Active'
                  onClick={() =>
                    handleActiveClick(
                      e.state.pageIndex * e.state.pageSize,
                      e.state.pageSize,
                      e.state.pageIndex,
                      e.row.original.status,
                      e.row.original._id
                    )
                  }
                />
              </h5>
            );
          } else {
            return (
              <h5>
                <input
                  type='button'
                  className='_inactive'
                  value='Inactive'
                  onClick={() =>
                    handleActiveClick(
                      e.state.pageIndex * e.state.pageSize,
                      e.state.pageSize,
                      e.state.pageIndex,
                      e.row.original.status,
                      e.row.original._id
                    )
                  }
                />
              </h5>
            );
          }
        },
      },
      {
        Header: 'Edit',
        Cell: e => {
          if (e.row.original._id) {
            return (
              <h5>

           <Pencil
             className="me-2"
             size={20}
             onClick={() =>{
               handleDropUpdate(
                 e.row.original._id,
                 e.row.original.godName,
                 e.row.original.godId,
                 e.row.original.startTime,
                 e.row.original.endTime,
                 e.row.original.day
                 );
                 setFlag(true)
             }
             }
           />
              </h5>
            );
          }
        },
      },
      {
        Header: 'Updated Date',
        Cell: e => {
          return <span>{moment(e.row.original.updatedAt).format("MMM Do YY")}</span>;
        },
      },
    ],

  );

  return (
    <>
      <div className='main aarti_section master_god'>
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>Mandir God <small>({totalCount})</small></h4>
            <div className='_btn'>
              <Button
                variant='primary'
                onClick={() => {
                  setShowMasterGodModal(true);
                }}
                className='by-btn as_btn me-0'
              >
                Add God
              </Button>
            </div>
          </div>
        </div>
        <div className='aarti_inner pt-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4 col-lg-3'>
                <Sidebar />
              </div>
              <div className='col-md-8 col-lg-9'>
                {loading ? (
                  <div className='center'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <>
                    <div className='row'>
                      <div className='container'>
                        <div className='d-flex align-items-center' style={{justifyContent:"flex-end"}}>
                          <h4 className='hd-list-f left-q'></h4>
                          <Button
                          variant='primary'
                          onClick={(e) => {
                          handleDrop(e)
                          }}
                          className='by-btn as_btn me-2 mt-2'
                          >
                           Day Wise God Preference
                         </Button>
                          <Form.Select
                            aria-label='categories'
                            className=' w-25 astroWatch-select-hover hd-cr'
                            value={contentType}
                            onChange={e => {
                              setContentType(e.target.value);
                              setItemsOffset(0);
                            }}
                          >
                            <option value='hindi'>Hindi</option>
                            <option value='english'>English</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  
                    <div className='row mt-3'>
                      {allGods &&
                        allGods.length > 0 &&
                        allGods?.map(item => (
                          <div className='col-md-6 col-lg-6'>
                            <div className='card mb-4' key={item?._id}>
                              <div className='card-body ' style={{ minHeight: '90px' }}>
                                <div className='god-image-grid' >
                                  <img
                                    
                                    src={item?.mandirGodImage ? `${item?.mandirGodImage}` : DefaultImage}
                                    className='card-img-top'
                                    alt='img'
                                  />
                                  <img
                                    
                                    src={item?.ABCGodImage ? `${item?.ABCGodImage}` : DefaultImage}
                                    className='card-img-top'
                                    alt='img'
                                  />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <div className='d-flex align-items-center mt-2'>
                                    <h3 className='heading_16 mb-0'>{item.godName} </h3>
                                    <h3 className='heading_16 mb-0 ms-1 god-day'>{(!item.day || item.day === "null" || item.day === null || item.day === ""||item.day === "undefined")? '' :`/ ${item.day}`}</h3>
                                  </div>
                                  <h3 className='heading_16 mb-0 mt-2  link-like'onClick={()=>{ navigate(`/godImages?godName=${item.godName}&language=${contentType}`)}} >Images</h3>
                                  <h3 className='heading_16 mb-0 mt-2  link-like'onClick={()=>{ navigate(`/aarti`)}}>Aarti</h3>
                                  <div className='icon' style={{display:"flex"}}>
                                    <FaEdit
                                      className='me-2 hd-cr'
                                      size={18}
                                      onClick={e => {
                                        setShowMasterGodModal(true);
                                        setSelectedItem(item);
                                      }}
                                    />
                                    <FaTrash
                                      className='dt-btn hd-cr me-1'
                                      size={18}
                                      onClick={e => {
                                        setShowDeleteModal(true);
                                        setSelectedItem(item);
                                      }}
                                    />
                                
                                     {item.status === 1 ? (
                                      <input
                                        type="button"
                                        className="_active me-1"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Active"
                                        onClick={() =>
                                          handleclick(item.status, item._id)
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="button"
                                        className="_inactive"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Inactive"
                                        onClick={() =>
                                          handleclick(item.status, item._id)
                                        }
                                      />
                                    )}
                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}
                <div className='pagination'>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next>'
                    onPageChange={handlePaginationClick}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(totalCount / ITEMS_PER_PAGE)}
                    previousLabel='< Previous'
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={itemsOffset / ITEMS_PER_PAGE}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showMasterGodModal && (
        <MasterGodForm
          show={showMasterGodModal}
          handleClose={() => handleModalClose()}
          onSubmit={handleFormSubmit}
          selectedItem={selectedItem}
          loader={loader}
          aartiList={aartiList}
          progress={progress}
        />
      )}
       <Modal
       centered
       scrollable
        show={showDrop}
        size="xl"
        className='modal-dialog-tb'
        style={{marginTop:"40px"}}
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDrop}
      >
        <Modal.Body>
       <DynamicDataTableWithCount
        columns={columns}
        data={tableData}
        notShowPage ={true}
        fetchData={fetchProfilesByPagination}
      />
      <hr/>
       {flag === true ?<>
       <h4>{capitalizeFirstLetter(day)}</h4>
       <Form>
       <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
        <Form.Label className="required" column sm="2">
          God Name
        </Form.Label>
        <Col sm="3">
          <Select
           value={
            selectedGodId
              ? {
                  value: selectedGodId,
                  label: selectedGodName,
                }
              : null
          }
          options={godList.map(d => ({
            value: d?._id,
            label: d?.godName ? d?.godName : 'No Selection',
          }))}
          onChange={(selectedOption) => {
            setSelectedGodId(selectedOption?.value || '');
            setSelectedGodName(selectedOption?.label || '')
          }}
        />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
        <Form.Label className="required" column sm="2">
          Start Time
        </Form.Label>
        <Col sm="3">
        <Form.Control
        type='time'
        placeholder='Time...'
        step="3600"
        name='time'
        value={startTime }
        onChange={(event) =>{
          const hours = event.target.value.split(":")[0];
          setStartTime(`${hours}:00`);
          // setStartTime(event.target.value)
        }}
      />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
        <Form.Label className="required" column sm="2">
          End Time
        </Form.Label>
        <Col sm="3">
        <Form.Control
        type='time'
        placeholder='Time...'
        step="3600"
        name='time'
        value={endTime}
        onChange={(event) =>{
          const hours = event.target.value.split(":")[0];
          setEndTime(`${hours}:00`);
          // setEndTime(event.target.value)
        }}
      />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
      <Form.Label className="" column sm="2">
          
        </Form.Label>
        <Col sm="3">
         <Button
            variant="secondary"
            className="as_btn edit-btn1"
            style={{marginLeft:0}}
          onClick={handleEdit}
          >
             Edit
          </Button>
          <Button
            variant="secondary"
            className="as_btn reset-btn"
          onClick={() =>setFlag(false)}
          >
             Close
          </Button>
          </Col>
      </Form.Group>
   
       </Form></>
       :""} 
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="as_btn reset-btn"
          onClick={handleCloseDrop}
          >
             Close
          </Button>
         
        </Modal.Footer>
      </Modal>
      <Modal
      centered
        show={showDeleteModal}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleModalClose}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default MasterGod;
