import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row, Table, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pencil, XOctagon, XLg } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import APIServicenew from '../utils/APIGeneralService';
import Select from 'react-select';
import moment from 'moment';

const Whatsappinteractivereply = () => {
  document.title = 'Whatsapp InterActive Reply';
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [newData, setNewData] = useState();

  const defaultState = {
    buttonIdArr: [],
    message: '',
  };

  const [state, setState] = useState(defaultState);
  const [templates, setTemplates] = useState([]);
  const [validated, setValidated] = useState(false);
  const [allDataCount, setAllDataCount] = useState();
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [loader, setLoader] = useState(false);
  const [skip, setSkip] = useState(0);

  async function handleCreate(event) {
    event.preventDefault();
    const form = event.currentTarget;
    let formData = {
      buttonIdArr: state?.buttonIdArr,
      message: state?.message,
    };

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setLoader(true);
      if (newData?._id) {
        const res = await APIServices.post(
          `/whatsAppApi/wAppInteractiveTemplateUpdate/${newData._id}`,
          formData,
          headers
        );
        if (res && res.status === 200) {
          await fetchData();
          let apiData = res?.data;
          if (apiData?.status === true) {
            toast.success(apiData?.message);
            setState(defaultState);
            setNewData([]);
            setLoader(false);
            setValidated(false);
          } else if (apiData?.status === false) {
            toast.error(apiData?.message);
            setLoader(false);
          }
        }
      } else {
        let response = await APIServices.post(`/whatsAppApi/addInteractiveBtnMsg`, formData, headers);
        if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status) {
            toast.success(apiData?.message);
            setState(defaultState);
            setNewData([]);
            setLoader(false);
            fetchData();
            setValidated(false);
          }
        }
      }
      setLoader(false);
    }
  }

  const fetchData = async () => {
    setLoading(true);
    let response = await APIServices.get(`/whatsAppApi/wAppInteractiveTemplateList/${skip}/50`, headers);
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData?.data);
        setAllDataCount(apiData?.data.length);
        setLoading(false);
      }
    }
  };
  const handleReplyArr = e => {
    let headlinear = e.target.value?.split(',');
    setState({ ...state, buttonIdArr: headlinear });
  };

  useEffect(() => {
    fetchData();

    setLoader(false);
  }, []);

  return (
    <>
      <div
        className='notifiction_area'
        style={{
          backgroundImage: 'url("/images/why_choose_us.jpg")',
        }}
      >
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>
              <Row>
                {newData?._id ? (
                  <Col>
                    <h5>
                      Edit Reply
                      {/* <span style={{ marginLeft: '6px' }}>
                        <XLg
                         style={{ width: '16px' }}
                          className='me-2'
                          size={20}
                          onClick={e => {
                            setNewData([]);
                            setState(defaultState);
                            setValidated(false);
                          }}
                        />
                      </span> */}
                    </h5>
                  </Col>
                ) : (
                  <Col>
                    <h5>
                      Whatsapp Interactive Reply Add <small>({allDataCount})</small>
                    </h5>
                  </Col>
                )}
              </Row>
            </h4>
          </div>
        </div>
        <div className='container-fluid'>
          <div className='notifiction_form box-shadow p-20 br-6 my-3'>
            <Form noValidate validated={validated} onSubmit={handleCreate}>
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='validationCustom01'>
                    <Form.Label class='required'>
                      Button Id<small>(please enter comma separated)</small>
                    </Form.Label>

                    <Form.Control
                      required
                      type='text'
                      className='form-control '
                      placeholder='Enter button id'
                      onChange={e => {
                        handleReplyArr(e);
                      }}
                      value={state?.buttonIdArr}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter button id.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='validationCustom01'>
                    <Form.Label class='required'>Message</Form.Label>
                    <Form.Control
                      required
                      type='text'
                      className='form-control '
                      placeholder='Enter message'
                      onChange={e => {
                        setState({ ...state, message: e.target.value });
                      }}
                      value={state?.message}
                    />
                    <Form.Control.Feedback type='invalid'>Please enter message.</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                  <div style={{ alignItems: 'center' }}>
                    {newData?._id ? (
                      <>
                        {loader ? (
                          <Button variant='secondary' className='as_btn'>
                            Processing...
                          </Button>
                        ) : (<>
                          <Button variant='secondary' className='as_btn ms-1' type='submit'>
                            Edit
                          </Button>
                            <Button variant="primary" onClick={(e) => {
                              setNewData([]);
                              setState(defaultState);
                              setValidated(false);
                            }} className="as_btn cancle_btn"  type="submit" >Cancel </Button></>
                        )}
                      </>
                    ) : (
                      <>
                        {loader ? (
                          <Button variant='secondary' className='as_btn'>
                            Processing...
                          </Button>
                        ) : (
                          <Button variant='secondary' className='as_btn ms-1' type='submit'>
                            Add
                          </Button>
                        )}
                      </>
                    )}
                  </div>
              </Row>
            </Form>
          </div>
        </div>

            <div className='table-responsive admin_grid_table campaign'>
              <Table className='w-1920'>
                <thead>
                  <tr>
                    <th>No</th>
                    <th> Button Id</th>
                    <th> Message</th>
                    <th>Date</th>
                    <th>Modified Date</th>
                    <th> Action</th>
                  </tr>
                </thead>
                {data?.map((item, i) => (
                  <tbody>
                    <tr key={item?._id}>
                      <td>{i + 1}</td>
                      <td>{item?.buttonIdArr.join(', ')}</td>
                      <td>{item?.message}</td>
                      <td>{moment(item.createdAt).format('DD MMM, YYYY hh:mm A')}</td>
                      <td>{moment(item.updatedAt).format('DD MMM, YYYY hh:mm A')}</td>
                      <td>
                        <Pencil
                          className='me-2 cursur'
                          size={20}
                          onClick={e => {
                            setNewData(item);
                            setState({
                              buttonIdArr: item.buttonIdArr,
                              message: item?.message,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
            </div>

      </div>

      <ToastContainer />
    </>
  );
};

export default Whatsappinteractivereply;
