import React, { useEffect, useState,useRef,useContext } from "react";
import { Card, Button, Modal, Form, Row, Col ,OverlayTrigger ,Tooltip } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import axios from 'axios'
import { Editor } from "@tinymce/tinymce-react";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import { FaEdit, FaTrash ,FaCopy} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../components/Shared/Sidebar";
import bimg from "../../src/imgs/banner_background.png";
import upimg from "../../src/imgs/default.jpg";
import BannerCrop from "./aspoojas/bannerCrop";
import Cookies from "universal-cookie";
import APIServicenew from "../utils/APIGeneralService";
import FestivalCrop from "./festivals/festivalCrop";
import { getBaseURL,setCookies } from "../utils/Helper";
import { SlugifyStringWithDash } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

const AstroTemple = () => {
  document.title = 'Temple';
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [selectedId, setSelectedId] = useState();
  const [showMore, setShowMore] = useState(false);
  const [show, setShow] = useState(false);
  const [showdt, setShowDt] = useState(false);
  const [ type , setType] = useState("english")
  const [allData , setAllData] = useState([])
  const [ contentType , setContentType] = useState ("")
  const [title, setTitle] = useState();
  const [slug,setSlug]=useState()
  const [ shortContent , setShortContent] = useState();
  const [location , setLocation] = useState();
  const [ pujaType , setPujaType] = useState();
  const [darshanTiming , setDarshanTiming] = useState();
  const [festivals , setFestivals] = useState();
  const [topContent , setTopContent] = useState("");
  const [bottomContent, setBottomContent] = useState("");
  const [video, setVideo] = useState("");
  const [audio, setAudio] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [bannerImage, setBannerImage] = useState("")
  const [bannerImagePreview, setBannerImagePreview] = useState();
  const [newData, setNewData] = useState();
  const [img, setImg] = useState();
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [len, setLen] = useState();
  const [errors, setErrors] = useState({});
  const[loader,setLoader]=useState(false)
  const [allDataCount , setAllDataCount] = useState()
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [cfileimage,setFileCimage]=useState()
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  //let type = location.state.value
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId");

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  const onImageChange = (event) => {
   
    setFile(event);
      setFile1(event)
    //  i have no idea so i am not removing this
    // if (event.target.files && event.target.files[0]) {
    //   setFile(URL.createObjectURL(event.target.files[0]));
    //   setFile1(event.target.files[0])
    // }
  }
  const onImageChangeBanner= (event) => {
    setBannerImagePreview(event);
      setBannerImage(event);
    // if (event.target.files && event.target.files[0]) {
    //   setPosterImagePreview(URL.createObjectURL(event.target.files[0]));
    //   setPosterImage(event.target.files[0]);
    // }
  };
  const handleClose_m = () => setShowMore(false);
  const handleClose = () => {
    setShow(false)
    setErrors("")
    setBannerCropImg()
  
  };
  const handleCloseDt = () => setShowDt(false);

  const handleShowMore = (item) => {
    setShowMore(true);
    setTitle(item?.title);
    setShortContent(item?.shortContent);
  };
  const handleShow =async (item) => {
    setFile("");
    setFile1("");
    setBannerImage("")
    setShow(true);
    setSelectedId(item?._id);
    setTitle(item?.title);
    setSlug(item?.slug)
    setVideo(item?.video ?item.video :"");
    setAudio(item?.audio ?item.audio :"");
    setCategory(item?.category?item?.category:"");
    setTags(item?.tags?item?.tags:"");
    setImg(item?.file);
    setShortContent(item?.shortContent);
    setTopContent(item?.topContent)
    setBottomContent(item?.bottomContent?item?.bottomContent:"");

    setLocation(item?.location);
    setPujaType(item?.pujaType);
    setDarshanTiming(item?.darshanTiming);
    setFestivals(item?.festivals);
    setBannerImagePreview(item?.bannerImage??"")

  };


  const handleShowDt = (id) => {
    setSelectedId(id);
    setShowDt(true);
  };

  function isValidURL(string) {
    var res = 
string.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
return (res !== null);
};

const validateInputs = () => {
  let isValid = false;
 
  const newErrors = {};

    if (!title ||title === "") 
    newErrors.title = "Please enter title.";

    if (!slug ||slug === "") 
      newErrors.slug = "Please enter slug.";
   
    // if (!contentType ||contentType === "") 
    // newErrors.contentType = "Please select content Type.";

    if (!shortContent ||shortContent === "") 
    newErrors.shortContent = "Please enter short Content.";

    if (!topContent ||topContent === "") 
    newErrors.topContent = "Please enter top Content.";

    if (!location ||location === "") 
    newErrors.location = "Please enter location.";
  
    if (!pujaType ||pujaType === "") 
    newErrors.pujaType = "Please enter puja Type.";
  
    if (!darshanTiming ||darshanTiming === "") 
    newErrors.darshanTiming = "Please enter darshan Timing.";
  
    if (!festivals ||festivals === "") 
    newErrors.festivals = "Please enter festivals.";

    if ( video !== "" && isValidURL(video) === false) 
    newErrors.video = "Please enter valid video url.";

    if (audio !== "" && isValidURL(audio) === false) 
    newErrors.audio = "Please enter valid audio url.";
  
    if (file1 === "" && (!img || img === "")) 
    newErrors.file = "Please add image.";

    return newErrors;
}; 

  async function handleEdit() {
    const formData = new FormData();
    if (file1) {
      formData.append("file", file1);
    }
    if (typeof bannerImage === "object") {
      formData.append("bannerImage", bannerImage);
    }
    formData.append("title", title);
    // formData.append('slug', slug);
    formData.append("shortContent", shortContent);
    formData.append("topContent", topContent);
    formData.append("bottomContent", bottomContent);
    formData.append("location", location);
    formData.append("pujaType", pujaType);
    formData.append("darshanTiming", darshanTiming);
    formData.append("festivals", festivals);
    formData.append("video", video);
    formData.append("audio", audio);
    // if (contentType === "english") {
    //   formData.append("contentLangEn", true);
    //   formData.append("contentLangHi", false);

    // } else if (contentType === "hindi") {
    //   formData.append("contentLangHi", true);
    //   formData.append("contentLangEn", false);
    // }
    formData.append("category", category);
    formData.append("tags", tags);
    formData.append("userId", astroUserId);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
   } else {
      let response = await APIServices.put(
        `astroTemple/editOne/${selectedId}`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          setFile1(null);
          setShow(false);
          setCropImg();
          setBannerCropImg()
          updateFile();
          setLoader(false)
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }
      }else if(response?.status === 400){
        let errData = response?.data;
        if(errData && errData.message){
          toast.error(errData?.message);
        }
      }
      setLoader(false)
    }
  }

  const fetchData = async () => {
    setLoading(true);
    let response = await APIServices.get(`astroTemple/getAll/${type}/${offset}/${perPage}`, headers);
    let data = response?.response?.data
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData?.Listdata)
        setAllDataCount(apiData.totalCount)
       
        setLoading(false);
      }
    }else{
      if(data?.status == 4){
        logout()
       }
    }
  };


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage);
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset);
  };

  async function updateFile(showToast) {
    let res = await APIServices.get(`astroTemple/updateFile`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
          toast.success(apiData?.message); 
        }
        fetchData();
      }
    }
  }

  const handleclick = async (status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append("status", datas);
    await APIServices.put(`astroTemple/updateStatus/${id}`, formData, headers)
      .then((res) => {
        updateFile()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloneHindi = async (id) => {
    const res = await APIServices.post(`astroTemple/hi/cloneById/${id}`, {userId: astroUserId}, headers)
    if(!res || (res && res.status !== 200)){
      toast.error("Something went wrong. Try Again!!", {
        position: toast.POSITION.TOP_RIGHT,
      })
      return;
    }
    const data = res?.data
    if(data && data.status === false){
      toast.warn(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if(data && data.status && data.message){
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const handledelete = async () => {
    let res = await APIServices.delete(
      `astroTemple/findByIdAndremove/${selectedId}`,
      headers
    );
    if (res?.status === 200) {
      let apiData = res?.data;
      setShowDt(false);
      setOffset(0)
      if (apiData?.status) {
        toast.success(apiData?.message);
        updateFile();
      }
    }
  };
  function isValidURL(string) {
      var res = 
string.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
  return (res !== null);
  };

  
  async function handleCreate(e) {
    const formData = new FormData();
    formData.append("title", title);
    formData.append('slug', slug);
    formData.append("shortContent", shortContent);
    formData.append("topContent", topContent);
    formData.append("bottomContent", bottomContent);
    formData.append("location", location);
    formData.append("pujaType", pujaType);
    formData.append("darshanTiming", darshanTiming);
    formData.append("festivals", festivals);
    formData.append("video", video);
    formData.append("audio", audio);
    // if (contentType === "english") {
    //   formData.append("contentLangEn", true);
    // } else if (contentType === "hindi") {
    //   formData.append("contentLangHi", true);
    // }
    formData.append("category", category);
    formData.append("tags", tags);
    formData.append("file", file1);
    // formData.append("bannerImage", bannerImage);
    if (typeof bannerImage === "object") {
      formData.append("bannerImage", bannerImage);
    }
    formData.append("userId", astroUserId);
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
      let response = await APIServices.post(
        `astroTemple/upload`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShow(false)
          toast.success(apiData?.message);
          setCropImg()
          setBannerCropImg()
          updateFile();
          setLoader(false)
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }
      }else if(response?.status === 400){
        let errData = response?.data;
        if(errData && errData.message){
          toast.error(errData?.message);
        }
      }
      setLoader(false)
    }
  }


  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    if( e === "topContent") key = e
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }

  useEffect(() => {
    fetchData();
  }, [offset ,type]);

 
  const[showcropbanner,setShowCropbanner]=useState(false)
  const[bannercropimg,setBannerCropImg]=useState()
  const[cbannerfileimage,setFileCbannerimage]=useState()

  useEffect(()=>{
    if(cbannerfileimage){
    onImageChangeBanner(cbannerfileimage)
    }
  },[bannercropimg,cbannerfileimage])



useEffect(()=>{
  if(cfileimage){
    onImageChange(cfileimage)
  }
},[cropimg,cfileimage])

const TinymceEditor = () =>{
  return(
    <>
    <Form.Group as={Row} className="mb-3">
    <Form.Label className="required" column sm="3">
     Top Content
    </Form.Label>
    <Col sm="9">
    <Editor
    // apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
    apiKey={process.env.REACT_APP_TINY_EDITER_KEY}
    cloudChannel='5-stable'
    disabled={false}
    // initialValue=""
    value={topContent}
    inline={false} 
    onEditorChange={value => {setTopContent(value);handleChange("topContent")}}
    plugins=''
    tagName='div'
    textareaName=''
    toolbar=''
    outputFormat='html'
    init={{
        height: 300, 
        // language: '',
        //menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
     ],
     toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help',

          image_title: true,
          automatic_uploads: true,
          selector: 'textarea', 
          file_picker_types: 'file image',
          // images_upload_url: `${process.env.REACT_APP_OTHER_API_URL}commonUpload/editor`,

          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*')

            input.onchange = async function () {
            var file = this.files[0];
            let formdata = new FormData();
            formdata.append("commonFile", file, file.name);
            const res = await axios.post(`${process.env.REACT_APP_OTHER_API_URL}commonUpload/editor`, formdata, { headers: { "Content-Type": "multipart/form-data" } })  
            if (res.status === 200) {
              if (res?.data?.status === false) {
                console.log('not 200', res?.data?.message);
              }
              else {
                cb(res.data.path)
              }
            } else {
              console.log('Failed in uploading BLOODY FILE')
            }
          };
          input.click();
   }} 
  }
  />
 
  <span  className="errorVal">{errors?.topContent}</span>
  </Col>
  </Form.Group>
  <Form.Group as={Row} className="mb-3">
    <Form.Label className="" column sm="3">
    Bottom Content
    </Form.Label>
    <Col sm="9">
    <Editor
    // apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
    apiKey={process.env.REACT_APP_TINY_EDITER_KEY}
    cloudChannel='5-stable'
    disabled={false}
    // initialValue=""
    value={bottomContent}
    inline={false} 
    onEditorChange={value => {setBottomContent(value);}}
    plugins=''
    tagName='div'
    textareaName=''
    toolbar=''
    // value=""
    outputFormat='html'
    init={{
        height: 300, 
        // language: '',
        //menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
     ],
     toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help',
          
          image_title: true,
          automatic_uploads: true,
          selector: 'textarea', 
          file_picker_types: 'file image',
          // images_upload_url: `${process.env.REACT_APP_OTHER_API_URL}commonUpload/editor`,

          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*')

            input.onchange = async function () {
            var file = this.files[0];
            let formdata = new FormData();
            formdata.append("commonFile", file, file.name);
            const res = await axios.post(`${process.env.REACT_APP_OTHER_API_URL}commonUpload/editor`, formdata, { headers: { "Content-Type": "multipart/form-data" } })  
            if (res.status === 200) {
              if (res?.data?.status === false) {
                console.log('not 200', res?.data?.message);
              }
              else {
                cb(res.data.path)
              }
            } else {
              console.log('Failed in uploading BLOODY FILE')
            }
          };
          input.click();
   }} 
    }
   
  />
  </Col>
  </Form.Group>
</>
  )
}

const renderFormModal = () => {

  return(
    <Modal show={show} backdrop="static" onHide={()=>{handleClose(); setCropImg()}} enforceFocus={false} scrollable={true}>
    <Modal.Header closeButton>
      <Modal.Title>{`${selectedId ? `Edit Temple/${type === "english" ?"English":"Hindi"}` : 'Add Temple/English'}`} </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
            Title
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Title..."
              name='title'
              defaultValue={title}
              isInvalid={!!errors?.title}
              className="form-control "
              onChange={(e) => {setTitle(e.target.value);handleChange(e)}}
            />
            <Form.Control.Feedback type="invalid">
                {errors?.title}
           </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {selectedId?
        <Form.Group as={Row}  className="mb-3 " controlId="formTitleInput">
        <Form.Label className="required" column sm="3">Slug</Form.Label>
        <Col sm="9">
        <Form.Control type="text" placeholder="slug..." name='slug' value={slug} readOnly />
         </Col>
      </Form.Group>
        :
        <Form.Group as={Row}  className="mb-3 " controlId="formTitleInput">
        <Form.Label className="required" column sm="3">Slug</Form.Label>
        <Col sm="9">
        <Form.Control type="text" placeholder="slug..." name='slug'
         isInvalid={!!errors?.slug} value={slug} 
        onChange={(e) => { setSlug(e.target.value ); handleChange(e)}}
        onBlur={(e) => { setSlug(SlugifyStringWithDash(e.target.value)) ; handleChange(e)}}
        />
        <Form.Control.Feedback type="invalid">
      {errors?.slug}
     </Form.Control.Feedback>
    </Col>
      </Form.Group>}

        {/* <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
            Content Type
          </Form.Label>
          <Col sm="9">
            <Form.Select
              aria-label="Default select example"
              required
              className=" form-control hd-cr"
              name="contentType"
              isInvalid={!!errors?.contentType}
              defaultValue={contentType}
              onChange={(e) => {setContentType(e.target.value);handleChange(e)}}
            >
              <option value="">Choose</option>
              <option value="english">English</option>
              <option value="hindi">Hindi</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors?.contentType}
           </Form.Control.Feedback>
          </Col>
        </Form.Group> */}
       <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
          Location
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Location..."
              className="form-control "
              name="location"
              defaultValue={location}
              isInvalid={!!errors?.location}
              onChange={(e) => {setLocation(e.target.value);handleChange(e)}}
            />
            <Form.Control.Feedback type="invalid">
                {errors?.location}
           </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required"column sm="3">
          Puja Type
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Puja Type..."
              className="form-control "
              isInvalid={!!errors?.pujaType}
              name="pujaType"
              defaultValue={pujaType}
              onChange={(e) => {setPujaType(e.target.value);handleChange(e)}}
            />
              <Form.Control.Feedback type="invalid">
                {errors?.pujaType}
           </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
          Darshan Timing
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Darshan Timing..."
              className="form-control "
              defaultValue={darshanTiming}
              isInvalid={!!errors?.darshanTiming}
              name="darshanTiming"
              onChange={(e) => {setDarshanTiming(e.target.value);handleChange(e)}}
            />
             <Form.Control.Feedback type="invalid">
                {errors?.darshanTiming}
           </Form.Control.Feedback>
          </Col>
         
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
          Festivals
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Festivals..."
              className="form-control"
              name="festivals"
              defaultValue={festivals}
              isInvalid={!!errors?.festivals}
              onChange={(e) => {setFestivals(e.target.value);handleChange(e)}}
            />
             <Form.Control.Feedback type="invalid">
                {errors?.festivals}
           </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
          Short Content
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              as="textarea"
              style={{ height: 150 }}
              placeholder="Short Content..."
              className="form-control"
              name="shortContent"
              defaultValue={shortContent}
              isInvalid={!!errors?.shortContent}
              onChange={(e) => {setShortContent(e.target.value);handleChange(e)}}
            />
             <Form.Control.Feedback type="invalid">
                {errors?.shortContent}
           </Form.Control.Feedback>
          </Col>
        </Form.Group>
         {/* editer*/}
         {TinymceEditor()}

        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label column sm="3">
            Video
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="url"
              placeholder=" Video Url..."
              className="form-control "
              name="video"
              isInvalid={!!errors?.video}
              defaultValue={video}
              onChange={(e) => {setVideo(e.target.value); handleChange(e)}}
            />
             <Form.Control.Feedback type="invalid">
                {errors?.video}
           </Form.Control.Feedback>
          </Col>
        </Form.Group>
 
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label column sm="3">
            Audio
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="url"
              placeholder="Audio Url..."
              className="form-control "
              name="audio"
              isInvalid={!!errors?.audio}
              defaultValue={audio}
              onChange={(e) => {setAudio(e.target.value);handleChange(e)}}
            />
            <Form.Control.Feedback type="invalid">
                {errors?.audio}
           </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="" column sm="3">
            Category
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              name='category'
            defaultValue={category}
            // isInvalid={!!errors?.category}
            placeholder="Category..."
            className="form-control "
            onChange={e => {setCategory(e.target.value);}}
            />
             {/* <Form.Control.Feedback type="invalid">
                {errors?.category}
           </Form.Control.Feedback> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="" column sm="3">
            Tags
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              name='tags'
              defaultValue={tags}
              // isInvalid={!!errors?.tags}
              placeholder="Tag..."
              className="form-control "
              onChange={e => {setTags(e.target.value);}}
            />
             {/* <Form.Control.Feedback type="invalid">
                {errors?.tags}
           </Form.Control.Feedback> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="" column sm="3">
                Banner Image
              </Form.Label>
            
              <label id="labimg">
              
              {bannercropimg?<img 
                // crossOrigin="anonymous" 
              className="imgs" 
              src={bannercropimg}
              onClick={()=>setBannerCropImg()}
              width="100%" alt="" />:<img 
              className="imgs" 
              src={ bannerImage
                ? bannerImage : bannerImagePreview ? bannerImagePreview : upimg}
              width="100%" alt="" />}

                <Form.Control
                // type="file"
                name="bannerImage"
                className="form-control"
                onClick={(e)=>{setShowCropbanner(true)}}
               
                size="40"
                accept="image/png, image/gif, image/jpeg"
                id="inputds"
              />
                </label>
            
            </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label className="required" column sm="3">
            Image
          </Form.Label>
          <label id="labimg">
            
        {cropimg?<img 
          // crossOrigin="anonymous" 
        className="imgs" 
        src={cropimg}
        onClick={()=>setCropImg()}
        width="100%" alt="" />:<img 
        className="imgs" 
        src={selectedId? img: file ? file : upimg}
        width="100%" alt="" />}

        <Form.Control
          // type="file"
          className="form-control "
          isInvalid={!!errors?.file}
          name="file"
          onClick={(e)=>{setShowCrop(true);handleChange(e)}}
         
          size="40"
          accept="image/png, image/gif, image/jpeg"
          id="inputds"
        />
        <Form.Control.Feedback type="invalid">
            {errors?.file}
           </Form.Control.Feedback>
          </label>
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
    {loader?<Button variant="primary"
    className="as_btn"
  >
    {`${selectedId ? 'Processing...' : 'Processing...'}`}
    </Button>:<Button variant="primary"
    className="as_btn"
    onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}>
    {`${selectedId ? 'Edit' : 'Add'}`}
    </Button>}
      
      <Button variant="secondary"
      className="as_btn"
      onClick={()=>{handleClose(); setCropImg()}}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>

  )
}

  return (
    <>

      <div className="main aarti_section">
      <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" >Mysterious Temple <small>({allDataCount})</small></h4>
              <div className="_btn">
                    <Button
                variant="primary"
                className="by-btn as_btn"
                onClick={()=>updateFile(true)}
              >
                Update File
              </Button>
              <Button
              className="by-btn as_btn me-0"
                variant="primary"
                onClick={handleShow}
              >
                Add Temple</Button>
              </div>
           </div>
        </div>

        <div className="aarti_inner pt-5">
           <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-3"> 
                 <Sidebar />
              </div>
              <div className="col-md-8 col-lg-9"> 
                  
                    {loading ? <div className="center"><Spinner animation="border" /></div> :
                     <>
                     <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="hd-list-f left-q"></h4>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                            value={type}
                            onChange={(e) => {setType(e.target.value); setOffset(0)}}
                          >
                            <option value="english">English</option>
                            <option value="hindi">Hindi</option>

                          </Form.Select>
                        </div>
                      </div>
                    </div>

          
                        <div className="row mt-3">
                          {
                            data?.map((item) =>
                            <div className="col-md-6 col-lg-4">
                              <div className="card mb-4"
                                key={item?._id}
                              >

                                <div className="card-header">
                                {item.video ? <iframe
                                        title="astrowatch-contentHi"
                                        width="560"
                                        height="240"
                                        className="w-100"
                                        src={item?.video}
                                        type="video/mp4"
                                        autoPlay
                                        controls
                                      ></iframe> : <img
                                        // crossOrigin="anonymous"
                                        src={(item?.file) ? item?.file : bimg}
                                        className="card-img-top"
                                        alt="img"
                                      />}

                                   <div className="icon" >
                                    <FaEdit
                                      className="me-2 hd-cr"
                                      size={20}
                                      onClick={(e) => {
                                        handleShow(item)
                                      }}
                                    />
                                   
                                     {item.status === true ? (
                                      <input
                                        type="button"
                                        className="_active me-1"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Active"
                                        onClick={() =>
                                          handleclick(item.status, item._id)
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="button"
                                        className="_inactive"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Inactive"
                                        onClick={() =>
                                          handleclick(item.status, item._id)
                                        }
                                      />
                                    )}
                                     {type === "hindi" ? "" :
                                      <OverlayTrigger placement='bottom' overlay={<Tooltip id='button-edit'>Clone</Tooltip>}>
                                      <FaCopy
                                        className="me-1 hd-cr"
                                        title="Hindi Clone"
                                        style={{color:"red"}}
                                        size={20}
                                        onClick={(e) => {
                                          handleCloneHindi(item?._id);
                                        }}
                                      />
                                    </OverlayTrigger>}
                                     <FaTrash
                                      className="dt-btn hd-cr"
                                      size={20}
                                      onClick={e => {
                                        handleShowDt(item._id)
                                      }}
                                    />
                                  </div>
                                   
                                </div>
                                <div className="card-body" >
                                <p className="cal-dat description font-500 mb-2">
                                    <span className="calicon">
                                      <i className="ri-calendar-line"></i>
                                    </span>
                                    {/* {new Date(item.updatedAt).toDateString()} */}
                                    {moment(item.updatedAt).format("DD MMM, YYYY hh:mm A")}
                                  </p>
                               
                                  <h5 className="card-title  heading_18 mb-2">{item.title}</h5>
                                  <p className="card-text description" >
                                  {  item.shortContent}
                                    
                                  </p>
                                </div>
                                <div className="card-footer text-center">
                                <Button
                                      className="btn-more as_btn"
                                      variant="primary"
                                      onClick={(e) => {
                                        handleShowMore(item)
                                      }}
                                    >
                                      see more
                                    </Button>
                                </div>
                              </div>
                              </div>
                            )
                          }
             
            </div>
          
          </>
        }
          <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div>
        </div>
        </div>
        </div>
        </div>
      </div>
      <Modal centered scrollable show={showMore} backdrop="static" onHide={handleClose_m}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{shortContent}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"
          className="as_btn reset-btn"
          onClick={handleClose_m}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       {renderFormModal()}

      <Modal
        show={showdt}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="success_btn"
            onClick={handledelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <FestivalCrop 
      showcrop={showcrop}
      setShowCrop={setShowCrop}
      setCropImg={setCropImg}
      cropimg={cropimg}
      
      setFileCimage={setFileCimage}/>

     <BannerCrop 
         showcropbanner={showcropbanner}
         setShowCropbanner={setShowCropbanner}
         setBannerCropImg={setBannerCropImg}
         bannercropimg={bannercropimg}
         cbannerfileimage={cbannerfileimage}
         setFileCbannerimage={setFileCbannerimage}/>
         </>
  );
};

export default AstroTemple;
