import React, { useEffect, useState, useRef, useContext, useCallback, useMemo } from 'react';
import { Container, Row, Col, Dropdown, Form, ToggleButton, Modal, ButtonGroup, Spinner, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import APIServicenew from '../utils/APIGeneralService';
import { formateDate, getCookies, setCookies } from '../utils/Helper';
import { UserContext } from '../context/UserContextProvider';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import moment from 'moment';
import Papa from 'papaparse';
import { Trash2, PencilFill, TrashFill, Pencil } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { FaSistrix } from "react-icons/fa";


const PujaAllBooking = () => {
  document.title = 'Puja Bookings';
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [totalChats, setTotalChats] = useState('');
  const [paidtotalChats, setPaidTotalChats] = useState("");
  const token = getCookies('accessToken');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();
  const searchKeyword = useRef(null);
  const [allbooking, setAllBooking] = useState([]);
  const [allbookingdownload, setAllBookingDownload] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [userForNote, setUserForNote] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [note, setNote] = useState('');
  const [loader, setLoader] = useState(false);
  const [puja, setPuja] = useState([]);
  const selectedPuja = useRef(null);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [countData,setCountData]=useState()
  const [flag, setFlag] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(40);
  const [searchKey, setSearchKey] = useState('');
  const [ viewDetails,setViewDetails] = useState([])
  const [ showSingleDataDetail,setShowSingleDataDetail ] = useState(false)


  // const [inputState, setInputState] = useState({
  //   startdate: '',
  //   enddate: '',
  // });

  const [inputStateP, setInputStateP] = useState({
    pujaDate: '',
  });

  const [source, setSource] = useState('');
  const handleSourceChange = e => {
    setSource(e.target.value);
    setOffset(0);
  };

  const [payment, setPayment] = useState('');
  const handlePaymentChange = e => {
    setPayment(e.target.value);
    setOffset(0);
  };
  const [country, setCountry] = useState('');
  const handleCountryChange = e => {
    setCountry(e.target.value);
    setOffset(0);
  };

  const [notes, setNotes] = useState('');
  const handleNotesChange = e => {
    setNotes(e.target.value);
    setOffset(0);
  };

  const [prasadAdd, setPrasadAdd] = useState('');
  const handlePrasadChange = e => {
    setPrasadAdd(e.target.value);
    setOffset(0);
  };

  const [dakshinaAdd, setDakshinaAdd] = useState('');
  const handleDakshinaChange = e => {
    setDakshinaAdd(e.target.value);
    setOffset(0);
  };

  const Input = props => {
    const { autoComplete = props.autoComplete } = props.selectProps;
    return <components.Input {...props} autoComplete={autoComplete} />;
  };

  const [selectedPujaSec, setSelectedPujaSec] = useState({ current: '' });
  // Transform puja data to the format expected by react-select
  const pujaOptions = [
    { value: '', label: 'All' },
    ...puja.map(item => ({
      value: item._id,
      label: `${item.title} (${moment(item.pujaDate).format('DD MMM, YYYY hh:mm A')})`,
    })),
  ];

  const handleChangeSec = selectedOption => {
    if (selectedOption) {
      setSelectedPujaSec({ current: selectedOption.value });
      handlePujaChangeSec(selectedOption);
      setOffset(0);
    } else {
      console.log('No option selected');
    }
  };

  const handlePujaChangeSec = selectedOption => {
    const value = selectedOption.value;

    // selectedPuja.current = e.target.value;
    setCookies('skip', 0);
  };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  // const handleInputChange = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, startdate: formattedDate }));
  // };

  // const handleInputFromChange = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, enddate: formattedDate }));
  // };

    const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRange, setSelectedRange] = useState("");
  
   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  const handleInputChangeP = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setInputStateP(prevState => ({ ...prevState, pujaDate: formattedDate }));
  };

  const handleReset = () => {
    // setInputState({
    //   startdate: '',
    //   enddate: '',
    // });
    setStartDate('')
    setEndDate('')
    setSelectedRange('')
    setInputStateP({
      pujaDate: '',
    });
    // selectedPujaSec.current=""
    setSelectedPujaSec({ current: '' });
    setSource('');
    setPayment('');
    setCountry('');
    setNotes('');
    setSearchKey('');
    setPrasadAdd('');
    setDakshinaAdd('');
    setFlag(true);
  };
  const handleFilterClick = () => {
    if (
      startDate ||
      endDate ||
      inputStateP?.pujaDate ||
      perPage ||
      offset ||
      pageIndex ||
      source ||
      payment ||
      country ||
      notes ||
      searchKey ||
      prasadAdd ||
      dakshinaAdd ||
      selectedPujaSec?.current
    ) {
      fetchedAllBooking(
        offset,
        perPage,
        0,
        startDate,
        endDate,
        inputStateP?.pujaDate,
        source,
        payment,
        country,
        notes,
        searchKey,
        prasadAdd,
        dakshinaAdd,
        selectedPujaSec?.current
      );
    }
  };

  useEffect(() => {
    fetchedAllBooking(offset, perPage, pageIndex);
    fetchData();
    handleCountData()
  }, [flag]);

  const handleDownload = data => {

    for (let puja of data) {
      if (puja.pujaId) {
        puja['Puja Name '] = puja?.pujaId?.title;
      }
      if (puja.name) {
        puja['Name'] = puja.name;
      }else{
        puja['Name'] = "-";
      }
      if (puja.gotra) {
        puja['Gotra'] = puja.gotra;
      }else{
        puja['Gotra'] = "-";

      }
      if (puja.address) {
        puja['Address'] = puja.address;
      }else{
        puja['Address'] = "-";
      }
      
      if (puja.pujaPlan) {
      puja['PujaPlan Name'] = puja?.pujaPlan.title ?? '';
      if (puja.currencySymbol) {
        puja['Currency Symbol'] = puja.currencySymbol;
      }
        puja['PujaPlan Price'] = puja?.pujaPlan?.price ?? 0;
      }
      if (puja.pujaId) {
        puja['Puja Date '] = moment(puja?.pujaId?.pujaDate).format('DD MMM, YYYY hh:mm A');
      }
      if (puja.prasad_add) {
        puja['Prasad Status'] = 
        puja?.prasad_add === 'no'
          ? 'no'
          : puja?.prasad_price === 0
          ? 0
          : puja?.prasad_price
      }else{
        puja['Prasad Status'] = "-"
      }
      if (puja.dakshina_add) {
        puja['Dakshina Status'] = 
        puja?.dakshina_add === 'no'
              ? 'no'
              : puja?.dakshina_price === 0
              ? 0
              : puja?.dakshina_price
      }else{
        puja['Dakshina Status'] = "-"
      }
      if (puja.paymentStatus) {
        puja['Payment Status'] = puja.paymentStatus;
      }
      if (puja.contactNumber) {
        puja['Contact Number'] = puja.contactNumber;
      }
      if (puja.type) {
        puja['Source'] = puja.type;
      }

      if (puja.createdAt) {
        puja['Booking date '] = moment(puja?.createdAt).format('DD MMM, YYYY hh:mm A');
      }
    
      if (puja?.members?.length > 0) {
        let nameStr = '';
        for (let userName of puja.members) {
          if (userName?.name) {
            nameStr += `${userName.name},`;
          }
        }

        puja['Members Name'] = nameStr?.replace(/,*$/, '');
      }
      if (puja.remark) {
        puja['Remark'] = puja.remark;
      }
      if (puja.address2) {
        puja['Address 2'] = puja.address2;
      }else{
        puja['Address 2'] = "-";
      }
      if (puja.city) {
        puja['City'] = puja.city;
      }else{
        puja['City'] = "-";
      }
      if (puja.state) {
        puja['State'] = puja.state;
      }else{
        puja['State'] = "-";
      }
      if (puja.country) {
        puja['Country'] = puja.country;
      }else{
        puja['Country'] = "-";
      }
      if (puja.postalCode) {
        puja['Postal Code'] = puja.postalCode;
      }else{
        puja['Postal Code'] = "-";
      }
      // if (puja.currency === 'INR') {
      //   puja['Price '] = `₹ ${puja?.Price} `;
      // } else {
      //   puja['Price '] = `$ ${puja?.Price} `;
      // }
      delete puja?.address2;
      delete puja.city;
      delete puja.state;
      delete puja.country;
      delete puja.postalCode;
      delete puja.alternateContactNumber;
      delete puja.chadawa_items;
      delete puja.pujaPlan;
      delete puja.pujaId;
      delete puja._id;
      delete puja.userId;
      delete puja?.members;
      delete puja.createdAt;
      // delete puja.Price;
      delete puja.status;
      delete puja.type;
      delete puja.contactNumber;
      delete puja.contactNumberMsk;
      delete puja.name;
      delete puja.gotra;
      delete puja.address;
      delete puja.paymentStatus;
      delete puja.remark;
      delete puja?.countryId;
      delete puja?.currency;
      delete puja?.currencySymbol;
      delete puja?.prasad_add;
      delete puja?.dakshina_add;
      delete puja?.prasad_price;
      delete puja?.dakshina_price;
      delete puja?.total_price;
      delete puja?.shrad_data;      
    }

    let csv = Papa.unparse(data, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveBlob(csvData, 'UserList.csv');
    else {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
      a.download = 'Pujabooking.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Puja Name',
      Cell: e => {
        return (
          <Link to={`/shared-puja?pujaId=${e.row.original?.pujaId?._id}`}>{e.row.original?.pujaId?.title ?? '_'}</Link>
        );
      },
    },
    {
      Header: 'Name',
      Cell: e => {
        return <span>{e.row.original?.name ?? '_'}</span>;
      },
    },
    {
      Header: 'Gotra',
      Cell: e => {
        return <span>{e.row.original?.gotra ?? '_'}</span>;
      },
    },

    {
      Header: 'Address',
      Cell: e => {
        return <span>{e.row.original?.address ?? '_'}{e.row.original?.city ? ","+`${e.row.original?.city}` : ""}{e.row.original?.state ? ","+`${e.row.original?.state}` : ""}{e.row.original?.country ? ","+`${e.row.original?.country}` : ""}{e.row.original?.postalCode ? ","+`${e.row.original?.postalCode}` : ""}</span>;
      },
    },
    {
      Header: 'PujaPlan Name',
      Cell: e => {
        return <span>{e.row.original?.pujaPlan?.title ?? '_'}</span>;
      },
    },
    {
      Header: 'PujaPlan Price',
      Cell: e => {
        return <span>{e.row.original?.currencySymbol + e.row.original?.pujaPlan?.price ?? '_'}</span>;
      },
    },
    {
      Header: 'pujaDate',
      Cell: e => {
        return <span>{e.row.original ? moment(e.row.original?.pujaId?.pujaDate).format(("DD MMM, YYYY hh:mm A")) : '_'}</span>;
      },
    },
    {
      Header: 'Prasad Status',
      Cell: e => {
        return (
          <span>
            {e.row.original?.prasad_add ? ( e.row.original?.prasad_add === 'no'
              ? 'no'
              : e.row.original?.prasad_price === 0
              ? 0
              : e.row.original?.currencySymbol + e.row.original?.prasad_price) : "_"}
          </span>
        );
      },
    },
    {
      Header: 'Dakshina Status',
      Cell: e => {
        return (
          <span>
            {e.row.original?.dakshina_add ? (e.row.original?.dakshina_add === 'no'
              ? 'no'
              : e.row.original?.dakshina_price === 0
              ? 0
              : e.row.original?.currencySymbol + e.row.original?.dakshina_price) : "_"}
          </span>
        );
      },
    },
    {
      Header: 'Payment Status',
      Cell: e => {
        return (
          (
            <span
              className={e.row.original.paymentStatus == "Paid" ? "text-success fw-bold font_14":e.row.original.paymentStatus == "Failed" ? "text-danger fw-bold font_14" : "text-pending fw-bold font_14"}
               >
                 {e.row.original.paymentStatus ?? "_"}
               </span>
            )
        ) 
      },
    },
    {
      Header: 'Contact Number',
      Cell: e => {
        return <span>{e.row.original?.contactNumberMsk ?? '_'}</span>;
      },
    },
    {
      Header: 'Source',
      Cell: e => {
        return <span>{e.row.original?.type ?? '_'}</span>;
      },
    },
    {
      Header: 'Booking Date',
      Cell: e => {
        return <span>{e.row.original ? moment(e.row.original.createdAt).format(("DD MMM, YYYY hh:mm A")) : '_'}</span>;
      },
    },
    {
      Header: 'Notes',
      Cell: e => {
        return (
          <div>
            <h5>
              <input
                type='button'
                className='Et-btn notes-btn'
                value='Notes'
                onClick={() => {
                  setUserForNote(e.row.original);
                  handleUserNotes(e.row.original.userId);
                }}
              />
            </h5>
            {/* {  e.row.original?.chadawa_items && e.row.original?.chadawa_items?.length > 0 && ( */}
                <button className='detail_btn' type='button' onClick={() => {
                      setViewDetails(e.row.original);
                      setShowSingleDataDetail(true)
                    }}>View Details</button>
            {/* )} */}
          </div>
        );
      },
    },
  ]);

  const handleNotesAdd = async () => {
    if (!note || note === '') {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?.userId,
        id: userForNote?.pujaId?._id,
        source: 'Marketing-Puja-Booking',
        name: userForNote?.pujaId?.title,
        type: adminUser?.adminType,
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
      };
      setLoader(true);
      const res = await APIServices.post(`userNotes/userNoteMessage`, payload, headers);

      if (res && res?.data?.status === true) {
        toast.success('Note added successfully');

        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote('');
        setLoader(false);
      } else {
        toast.error(res?.data?.message);

        setLoader(false);
      }
      setLoader(false);
    }
  };
  const handleChange = () => {
    setError(false);
  };
  const handleCloseModal = () => {
    setShow(false);
    setError(false);
  };

  const handleUserNotes = async id => {
    const res = await APIServices.get(`userNotes/getDetailById/${id}`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setNotesData(res.data.data);
        setShow(true);
      } else {
        console.log('error');
      }
    }
  };

  const fetchData = async () => {
    let response = await APIServices.get(`astroPooja/pujalisting`, headers);
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setPuja(apiData.Listdata);
      }
    }
  };


  const fetchedAllBookingDownload = async () => {
    const res = await APIServices.get(
      `/pujaBooking/getAllPujaBooking?searchKey=${searchKey}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&pujaDate=${inputStateP?.pujaDate}&source=${source}&pujaId=${selectedPujaSec?.current}&status=${payment}&currency=${country}&isNotes=${notes}&prasad_add=${prasadAdd}&dakshina_add=${dakshinaAdd}`,
      headers
    );
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        setAllBookingDownload(resData?.Listdata);
        handleDownload(resData?.Listdata);
      }
    }
  };

  const fetchedAllBooking = useCallback(
    async (offset, perPage, index) => {
      try {
        // let page =offset/perPage
        let res = await APIServices.get(
          `/pujaBooking/getAllPujaBooking/${offset}/${perPage}?searchKey=${searchKey}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&pujaDate=${inputStateP?.pujaDate}&source=${source}&pujaId=${selectedPujaSec?.current}&status=${payment}&currency=${country}&isNotes=${notes}&prasad_add=${prasadAdd}&dakshina_add=${dakshinaAdd}`,
          headers
        );
        let data = res?.response?.data;
        if (res && res.status === 200) {
          let resData = res.data;
          if (resData && resData.status==true) {
            let newData = [...resData.Listdata];
            setUsers(newData);
            setTotalChats(resData.totalCount[0]?.totalCount);
            setPaidTotalChats(resData.paidcounts)
            setPageCount(Math.ceil(resData.totalCount[0]?.totalCount / perPage));
            setFlag(false);
          }else{
            setUsers([]);
            setTotalChats("");
            setPaidTotalChats("")
            setPageCount(0);
            setFlag(false);
          }
        } else {
          if (data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setOffset(offset);
      setPerPage(perPage);
      setPageIndex(index);
    },
    [
      pageIndex,
      offset,
      searchKey,
      selectedPujaSec?.current,
      startDate,
      endDate,
      inputStateP?.pujaDate,
      source,
      payment,
      country,
      notes,
      prasadAdd,
      dakshinaAdd,
    ]
  );

  const handleCountData = async (id) => {
    const res = await APIServices.get(`pujaBooking/getAdminPujaCount`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.resData);
      } else {
        console.log("error");
      }
    }
  };

  // useEffect(() => {
  //   fetchedAllBooking(offset, perPage, pageIndex);
    
  // }, []);

  

  return (
    <>
      <div className='gita_quote_heading'>
        <Container>
            <div className='overflow-x-auto'>
              <div className='counter-wrapper d-flex align-items-center justify-content-between'>
                <h4 className='hd-list-f left-q counter-top'>
                  All Puja Bookings <small>{paidtotalChats}/{totalChats}</small>
                </h4>
                <h4 className="hd-list-f font_20 left-q counter-top">
                  Today Puja <small>{countData?.usersAddedPaidToday}/{countData?.usersAddedToday}</small>
                  </h4>
                  <h4 className="hd-list-f font_20 left-q counter-top">
                    This Week Puja <small>{countData?.usersAddedPaidThisWeek}/{countData?.usersAddedThisWeek}</small>
                  </h4>
                  <h4 className="hd-list-f font_20 left-q counter-top">
                    This Month Puja <small>{countData?.paidPujaThisMonth}/{countData?.usersAddedThisMonth}</small>
                  </h4>
                  {/* <h4 className="hd-list-f font_20 left-q counter-top">
                    This Month Paid Puja <small>{countData?.paidPujaThisMonth}</small>
                  </h4> */}
              </div>
            </div>
        </Container>



      </div>
      <div className='chat_window puja_booking-wrapper'>
        <Row className='mlist'>
          <div class='chat_search_box '>
            <div className='chat-search-field'>
              <span className='chat-bot-search-icon'>
                {/* <i className='fa fa-search'></i> */}
                <FaSistrix />
              </span>
              <input
                type='text'
                className='form-control form-input w-50'
                placeholder='Search by name and contact no.'
                onChange={e => {
                  setSearchKey(e.target.value);
                  setOffset(0);
                }}
                value={searchKey}
              />
            </div>
            <hr />

            <Row className='mt-3 align-items-end'>
              <Col md={3}>
                <Form.Label>Source</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={e => {
                    // handleSource(e);
                    handleSourceChange(e);
                  }}
                  // value={Sourcedata.current.source}
                  value={source}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  <option className='text-capitalize' value='app'>
                    App
                  </option>
                  <option className='text-capitalize' value='Admin'>
                    Admin
                  </option>
                  <option className='text-capitalize' value='Lead'>
                    Lead
                  </option>
                  <option className='text-capitalize' value='Web'>
                    Web
                  </option>
                  <option className='text-capitalize' value='Global'>
                    Global
                  </option>
                </Form.Select>
              </Col>
              <Col md={3}>
                <Form.Label>Payment Status</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={e => {
                    // handlePayment(e);
                    handlePaymentChange(e);
                  }}
                  value={payment}
                  // value={Paymentdata.current.status}
                >
                  <option className='fw-bold' value=''>
                    All
                  </option>
                  <option className='text-capitalize' value='Failed'>
                    Failed
                  </option>
                  <option className='text-capitalize' value='Pending'>
                    Pending
                  </option>
                  <option className='text-capitalize' value='Paid'>
                    Paid
                  </option>
                </Form.Select>
              </Col>
              <Col md={3}>
                <Form.Label>Puja</Form.Label>
                {/* <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={(e)=>{handlePujaChange(e); setSkip(0)}}
                  value={selectedPuja.current}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  {puja.map((item, index) => {
                    return (
                      <>
                        <option className='text-capitalize' value={item._id}>
                          {item.title}{' '}
                          ({moment(item.pujaDate).format('lll')})
                        </option>
                      </>
                    );
                  })}
                </Form.Select> */}

                <Select
                  components={{ Input }}
                  autoComplete='new-password'
                  options={pujaOptions}
                  value={
                    selectedPujaSec.current === ''
                      ? ''
                      : pujaOptions.find(option => option.value === selectedPujaSec.current)
                  }
                  onChange={handleChangeSec}
                  classNamePrefix='react-select'
                  // className='fw-bold text-capitalize custom-datepicker'
                />
              </Col>
              <Col md={3} className=''>
                <Form.Label>Puja Date</Form.Label>

                {/* <input
                  className='form-ch form-control'
                  name='pujaDate'
                  type='date'
                  onChange={e => {handleInputChangeP(e); setSkip(0)}}
                  value={inputStateP.pujaDate}
                /> */}
                <DatePicker
                  placeholderText={'Select a puja date'}
                  className='form-control form-ch fil-date-pick w-100'
                  name='pujaDate'
                  selected={inputStateP.pujaDate ? new Date(inputStateP.pujaDate) : null}
                  onChange={date => {
                    handleInputChangeP(date);
                    setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col>
              {/* <Col md={2}>
                <Form.Label className='mt-4'>Search By Booking Date</Form.Label>

                

                <DatePicker
                  placeholderText={'From date'}
                  className='form-control form-ch fil-date-pick '
                  name='startdate'
                  selected={inputState.startdate ? new Date(inputState.startdate) : null}
                  onChange={date => {
                    handleInputChange(date);
                    setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col>

              <Col md={2} style={{ marginTop: '56px' }}>
                <DatePicker
                  placeholderText={'To date'}
                  className='form-control form-ch fil-date-pick'
                  name='enddate'
                  selected={inputState.enddate ? new Date(inputState.enddate) : null}
                  onChange={date => {
                    handleInputFromChange(date);
                    setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col> */}
             
              <Col md={3}>
                <Form.Label className='mt-3'>Country</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={e => {
                    // handleCountry(e);
                    handleCountryChange(e);
                  }}
                  // value={Countrydata.current.currency}
                  value={country}
                >
                  <option value='all'>All</option>
                  <option value='USD'>USA</option>
                  <option value='INR'>India</option>
                </Form.Select>
              </Col>
              {/* <Col md={2} className='justify-content-end pe-0 align-items-end'>
                 <Form.Label>End Date</Form.Label>
               </Col>
               <Col md={2}>
                 <input
                   className='form-control'
                   name='enddate'
                   type='date'
                   onChange={e => handleInputChange(e)}
                   value={inputState.enddate}
                 />
               </Col> */}
              <Col md={3}>
                <Form.Label className='mt-3'> Notes</Form.Label>

                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr  form-ch'
                  onChange={e => {
                    // handleIsNotes(e);
                    handleNotesChange(e);
                  }}
                  value={notes}
                  // value={IsNotes.current.isNotes}
                >
                  <option className='fw-bold'> All</option>
                  <option className='text-capitalize' value='yes'>
                    Yes{' '}
                  </option>
                  {/* <option className='text-capitalize' value='no'> No </option> */}
                </Form.Select>
              </Col>
              <Col md={3}>
                <Form.Label className='mt-3'> Prasad Add</Form.Label>

                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr  form-ch'
                  onChange={e => {
                    handlePrasadChange(e);
                  }}
                  value={prasadAdd}
                >
                  <option className='fw-bold' value=''>
                    All
                  </option>
                  <option className='text-capitalize' value='yes'>
                    Yes
                  </option>
                  <option className='text-capitalize' value='no'>
                    No
                  </option>
                </Form.Select>
              </Col>
              <Col md={3}>
                <Form.Label className='mt-3'> Dakshina Add</Form.Label>

                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr  form-ch'
                  onChange={e => {
                    handleDakshinaChange(e);
                  }}
                  value={dakshinaAdd}
                >
                  <option className='fw-bold' value=''>
                    All
                  </option>
                  <option className='text-capitalize' value='yes'>
                    Yes
                  </option>
                  <option className='text-capitalize' value='no'>
                    No
                  </option>
                </Form.Select>
              </Col>
              <Col md={3}>
               <Form.Label className='mt-3'>Search By Booking Date</Form.Label>
               <div className="date-range-wrapper">  
                  <div className='select-icon-wrapper'>
                    <select
                    className="select-range"
                        value={selectedRange}
                        onChange={(e) => handleDateRange(e.target.value)}
                      >
                        <option value="disabled">Select Date Range</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="thisWeek">This Week</option>
                        <option value="last7Days">Last 7 Days</option>
                        <option value="thisMonth">This Month</option>
                        <option value="lastMonth">Last Month</option>
                        <option value="customDate">Custom Date</option>
                    </select>
                    <img src='/images/down-arrow.png' className='select-icons'/>
                  </div>

                    {selectedRange === "customDate" && (
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                        onChange={(update) => {
                          const [start, end] = update;
                          setStartDate(start);
                          setEndDate(end);
                        }}
                        // isClearable={true}
                        placeholderText="Select custom date range"
                        dateFormat="yyyy-MM-dd"
                      />
                    )}
                  </div>
              </Col>
              <Col md={4} className='mt-3 d-flex ps-0 ms-2'>
                <Button
                  variant='secondary'
                  className='as_btn ms-1'
                  onClick={() => {
                    handleFilterClick();
                    // setOffset(0);
                  }}
                >
                  Filter
                </Button>
                <Button className='as_btn ms-2 reset-btn' variant='primary' onClick={handleReset}>
                  Reset
                </Button>
                {adminUser?.adminType === "super_admin" ?
                  (
                      <Button
                        className='as_btn ms-2 download-btn'
                        variant='primary'
                        onClick={() => {
                          fetchedAllBookingDownload();
                        }}
                      >
                        Download Booking
                      </Button>
                  )
                
                : ""}

              </Col>
            </Row>
          </div>
          <div className='d-flex justify-content-center align-items-left w-100 p-0 mt-2 custom-pagination'></div>
          {/* )} */}
        </Row>
        {users && users.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={users}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: perPage,
            }}
            fetchData={fetchedAllBooking}
            // forcePage={offset / perPage}
          />
        ) : (
          <h2 className='no_records'>Records not Available</h2>
        )}
      </div>
      <Modal
        show={show}
        size='md'
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <Modal.Header className='modal-header-hd' closeButton>
          <Modal.Title> Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='aa d-flex'>
            <span className='me-3'> Name :- {userForNote?.name} </span>
            <span> Contact Number :- {userForNote?.contactNumberMsk} </span>
          </div>

          <Row>
            <Col>
              <Form.Group as={Row} className='my-3' controlId='formAartiInput'>
                <Col sm='12'>
                  <Form.Control
                    as='textarea'
                    name='content'
                    placeholder='Type here...'
                    value={note}
                    style={{ height: 100 }}
                    onChange={e => {
                      handleChange();
                      setNote(e.target.value);
                    }}
                  />
                  {error ? <p className='text-danger'>Please enter your note</p> : ''}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className='py-2 px-0'>
            {loader ? (
              <Button variant='primary' className='as_btn'>
                Processing...
              </Button>
            ) : (
              <Button variant='primary' className='as_btn' onClick={handleNotesAdd}>
                Add
              </Button>
            )}
            <Button variant='secondary' className='as_btn' onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>

          <h6 className='aa mt-2'> Notes History :-</h6>

          {notesData && notesData?.notes?.length > 0 ? (
            <>
              {notesData?.notes &&
                notesData?.notes.length > 0 &&
                notesData?.notes?.map((item, index) => (
                  <>
                    <Row className='mt-2'>
                      {item?.source && item?.source != '' ? (
                        <Col xs={5}>
                          <span className='aa'>Source :- </span>
                          <span className='item-msg'>{item.source ? item.source : ''} </span>
                        </Col>
                      ) : (
                        ''
                      )}

{item?.source && item?.source !== "" && (
    (item.source === "Leads-Chat-Orders" || item.source === "Leads-Call-Orders") ? (
        item?.sourcename && item.sourcename !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chat-Orders") ? (
                    <span className="aa">Chat Id :- </span>
                ) : (
                    <span className="aa">Call Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Chadhawa-List" || 
         item.source === "Leads-Manokamna-List" || 
         item.source === "Marketing-Chadhawa-List" ||
         item.source === "Marketing-Manokamna-List") ? (
        item?.sourceid && item.sourceid !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chadhawa-List" || item.source === "Marketing-Chadhawa-List") ? (
                    <span className="aa">Chadawa Id :- </span>
                ) : (
                    <span className="aa">Manokmana Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourceid?.length <= 50 ? item?.sourceid : `${item?.sourceid?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Puja-Booking" || 
         item.source === "Leads-Puja-Suggestion" ||
         item.source === "Marketing-Puja-Booking" ||
         item.source === "Marketing-Puja-Feedback" ||
         item.source === "Marketing-Puja-Suggestion") ? (
        item?.sourcename && item.sourcename !== "" && item?.sourceid && (
            <Col xs={7}>
                <span className="aa">Puja Name :- </span>
                <span className="item-msg">
                    <Link to={`/shared-puja?pujaId=${item?.sourceid}`}>
                        {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                    </Link>
                </span>
            </Col>
        )
    ) : null
)}

                    </Row>
                    <Row className='notes-item'>
                      <Col xs={9}>
                        <span className='item-name aa'>Note :-</span>
                        <span className='item-msg'>{item?.message}</span>
                      </Col>
                      <Col xs={3}>
                        <div className='text-end d-flex flex-column'>
                          <span className='text-primary item-name aa'>{item?.responderName || item?.adminName}</span>
                          <span className='item-text'>
                            {item?.createdAt ? moment(item?.createdAt).format('DD MMM, YYYY hh:mm A') : ''}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </>
                ))}
            </>
          ) : (
            <>
              <h4 className='text-center text-secondary'>Not Available</h4>
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* show Detail data */}

      <Modal
      className="astrologer_detail-modal puja-booking-modal"
        show={showSingleDataDetail}
        size="md"
        scrollable={true}
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={()=>setShowSingleDataDetail(false)}
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Booking Summary </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
               <div>
                  <div className='pb-1 mb-2 border-bottom border-2 border-gray'>
                        <h4 className='mb-2 fw-bold'>Puja Info : </h4>
                        <div className='row align-items-start gy-1 mb-2'>
                          <div className='col-md-6 d-flex justify-content-between align-items-start'>
                            <p className='my-1 w-30 fw-semibold'>Address 1:</p>
                            <p className='my-1 w-70'>{viewDetails?.address}</p>
                          </div>
                          <div className='col-md-6 d-flex justify-content-between align-items-start'>
                            <p className='my-1 w-30 fw-semibold'>Address 2:</p>
                            <p className='my-1 w-70'>{viewDetails?.address2}</p>
                          </div>
                          <div className='col-md-6 d-flex justify-content-between align-items-center'>
                            <p className='my-1 w-30 fw-semibold'>City:</p>
                            <p className='my-1 w-70'>{viewDetails?.city}</p>
                          </div>
                          <div className='col-md-6 d-flex justify-content-between align-items-center'>
                            <p className='my-1 w-30 fw-semibold'>State:</p>
                            <p className='my-1 w-70'>{viewDetails?.state}</p>
                          </div>
                          <div className='col-md-6 d-flex justify-content-between align-items-center'>
                            <p className='my-1 w-30 fw-semibold'>Country:</p>
                            <p className='my-1 w-70'>{viewDetails?.country}</p>
                          </div>
                          <div className='col-md-6 d-flex justify-content-between align-items-center'>
                            <p className='my-1 w-30 fw-semibold'>Postal Code:</p>
                            <p className='my-1 w-70'>{viewDetails?.postalCode}</p>
                          </div>
                        </div>
                  </div>
      
                  <div className='pb-1 mb-2 border-bottom border-2 border-gray'>
                    <div className='d-flex align-items-center mb-1'>
                        <p className='w-25 mb-0 fw-semibold'>Puja Price: </p>
                        <div className='d-flex justify-content-between  align-items-center  w-75'>
                          <p className='my-1'>({viewDetails?.pujaPlan?.title})</p>
                          <p className='my-1'>{viewDetails?.currencySymbol}{viewDetails?.pujaPlan?.price}</p>
                        </div>
                  
                    </div>

                    <div className='d-flex justify-content-between align-items-center mb-1'>
                    <p className='mb-0 fw-semibold'>Dakshina Price :</p>
                      <p className='my-1'>{viewDetails?.dakshina_price === 0 ? "No" : viewDetails?.dakshina_price === null ? "No" : `${viewDetails?.currencySymbol}${viewDetails?.dakshina_price}.00` }</p>
                    </div>
                
                    <div className='d-flex justify-content-between align-items-center mb-1'>
                    <p className='mb-0 fw-semibold'>Prasad Price :</p>
                      <p className='my-1'>{viewDetails?.prasad_price === 0 ? "No" : viewDetails?.prasad_price === null ? "No" : `${viewDetails?.currencySymbol}${viewDetails?.prasad_price}.00` }</p>
                    </div>
                  </div>

              
               {viewDetails && viewDetails?.chadawa_items?.length > 0 ?
                  (<>
                  
                      <div className='py-2 my-2 border-bottom border-2 border-gray'>
                        <h5 className='fw-bold'>Chadhawa Items : </h5>
                        {viewDetails?.chadawa_items?.map((items,ind)=>{
                          return(<>
                          <div className='d-flex justify-content-between mb-0' key={items?.chadawa_samgri_id}>
                            <p className='my-1 fw-semibold'>{items?.title} :</p>
                            <p className='my-1'  >{viewDetails?.currencySymbol}{items?.price}.00</p>
                          </div>
                          
                          
                          </>)
                        })}
                      </div> 
                  </>
                  
                  ): "" }

                  
            </div>
              <div className='d-flex justify-content-between my-2 pb-2'>
                <span className='fw-bold'> Total Amount :  </span>
                <span className='fw-bold'>  {viewDetails?.currencySymbol}{ viewDetails?.total_price}.00 </span>
              </div>

              {viewDetails?.alternateContactNumber &&
                (<>
                      <hr/>
                      <div className='d-flex mt-2'>
                        <span className='fw-bold'> Whatsapp Number :  </span>
                        <span className="ms-5"> {viewDetails?.alternateContactNumber} </span>
                      </div>
                </>
                )
              }

              {viewDetails?.shrad_data && viewDetails?.shrad_data.length>0 && 
                <div>
                  <hr/>
                  <h4 className='mt-3 mb-3'>Pitra Details : </h4>
                </div>
              }
              {
                viewDetails?.shrad_data && viewDetails?.shrad_data.length>0 && (
                  <div className='col-md-12 d-flex justify-content-between gap-1'>
                  <p className='fw-bold'>Day:  </p>
                  <p className='fw-bold'>Pitra Name:  </p>
                  <p className='fw-bold'>Price:  </p>
                  
              </div>
                )
              }

              { viewDetails?.shrad_data && viewDetails?.shrad_data.length>0 && 
                viewDetails?.shrad_data.map((datas, ind)=>{
                  return(
                    <div>
                      
                      <div className='col-md-12 d-flex justify-content-between'>
                            <p className='col-md-4'>{datas?.day}</p>
                            <p className='col-md-4 text-center'>{datas?.pitra_name}</p>
                            <p className='col-md-4 text-md-end'>{viewDetails?.currencySymbol}{datas?.price}.00</p>
                      </div>
                   
                    </div>
                  )
                })
               }
              

            </Row>
            <Row>
              
            </Row>
          </Container>
        </Modal.Body> 
        <Modal.Footer>
          <Button
            variant="secondary"
            className="as_btn"
            onClick={()=>setShowSingleDataDetail(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default PujaAllBooking;

// chat_window after div row class add when table not full show
