import { useEffect, useState ,useContext } from "react";
import { Button, Modal, Row ,Form ,Col} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContextProvider";
import ReactPaginate from 'react-paginate'
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash,FaArrowLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import bimg from "../../src/imgs/banner_background.png";
import ModalComponent from "../components/Shared/ModalComponent";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import { getBaseURL ,setCookies } from "../utils/Helper";
import { Heart, HeartFill} from 'react-bootstrap-icons';
import upimg from "../../src/imgs/default.jpg";
import CropModal from "../components/Shared/CropModal";

const PujaPlanSelectionImgMaster=()=> {
  document.title = 'Puja Plan Selection Image Master';
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const  {refreshToken} = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId")
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [title, setTitle] = useState("");
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [loading, setLoading] = useState()
  const [file1, setFile1] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showdt, setShowDt] = useState(false)
  const [allData , setAllData] = useState([])
  const [allDataCount , setAllDataCount] = useState([])
  const [data, setData] = useState();
  const [ selectedId ,setSelectedId] = useState()
  const [errors, setErrors] = useState({});
  const[loader,setLoader]=useState(false)
  const navigate = useNavigate();
  const [progress ,setProgress] = useState(0)

  const [imagePreview, setImagePreview] = useState();
  
  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }

  useEffect(() => {
    fetchData();
  }, [offset]);

  async function fetchData() {
    setLoading(true)
    let response = await APIServices.get(`pujaPlanSelectionImg/getAll/${offset}/${perPage}`, headers)
    let data = response?.response?.data
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata)
        setAllDataCount(apiData.totalCount)
        setLoading(false)
      }
    }else{
      if(data.status == 4){
     logout()}
    }
  }
  const handleShow = async (item) => {

    setShowModal(true);
    setTitle(item?.title)
    setSelectedId(item?._id);
    setImagePreview(item?.file)
 
  };
  const handleClose = () => {
    setErrors("")
    setFile1("")
    setTitle("")
    setImagePreview("")
    setSelectedId("")
    // setFileCaartiimage("")
    setShowModal(false)

  };


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset)
  }

  const validateInputs = () => {
    const newErrors = {};

      if (!title ||title === "") 
      newErrors.title = "Please enter title.";

      if ((!file1 && file1 === "")) 
      newErrors.file = "Please upload image.";
  
      return newErrors;
  };  

  async function handleCreate() {
    let payload = new FormData();
    payload.append('title',title);
    if (file1) {
    payload.append('file',file1);
    }
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else{
      let response = await APIServices.post(`pujaPlanSelectionImg/upload`, payload, { ...headers, "Content-Type": "multipart/form-data" },(progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted)
    })
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShowModal(false);
          toast.success(apiData?.message);
          setLoader(false)
          // setFileCaartiimage("")
          setFile1("")
          setTitle("")
          setImagePreview("")
          fetchData();
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }
      }
      setLoader(false)
    }
  }
  
  async function handleEdit() {
    const formData = new FormData();
    if (file1) {
    formData.append("file", file1);
    }
   
    const headers = { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data", };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else {
      let response = await APIServices.put(
        `pujaPlanSelectionImg/editOne/${selectedId}`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          setShowModal(false);
          setSelectedId()
          setFile1("")
          setTitle("")
          setImagePreview("")
          fetchData();
          setLoader(false)
        }else if (apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }
      }
      setLoader(false)
    }
  }


  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = (id) => {
    setShowDt(true)
    setSelectedId(id)
  }
  const handledelete = async () => {
    let res = await APIServices.delete(`pujaPlanSelectionImg/findByIdAndremove/${selectedId}`, headers)
    if (res?.status === 200) {
      setShowDt(false)
      setSelectedId()
      setOffset(0)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData()
      }
    }
  }
 
  function handleChange(e, field ) {
    let key, value;
    key = e?.target?.name;
    if (field === "file") {
      value = e?.target?.files?.[0];
    }
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }
  const onImageChange = (event) => {
      if (event.target.files && event.target.files[0]) {
        setImagePreview(URL.createObjectURL(event.target.files[0]));
        setFile1(event.target.files[0]);
        
      }
  }

  return (
    <>
      <div className="main aarti_section">
        <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" >
           <FaArrowLeft
              className="hd-cr "
              style={{marginRight: "15px"}}
              size={25}
              onClick={() => navigate(-1)}/>
            Plan Image Selector <small>({allDataCount})</small></h4>

           <div className="_btn">
              <Button
               className="by-btn as_btn me-0"
                variant="primary"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add Image
              </Button>
              {/* &nbsp;&nbsp; */}
           </div>
           </div>
        </div>
        <div className="aarti_inner pt-4">
           <div className="container">
           <div className="row">
           <div className="col-md-12 col-lg-12"> 
          {loading ? <div className="center"><Spinner animation="border" /></div> :
              <>
        
        <hr style={{color:"black"}}/>
            <div className="row">
                 
              {
                allData?.map((item) =>
                <div className="col-md-6 col-lg-4 col-xl-3">
                  <div className="card mb-3 Reels_video" key={item?._id} >
                    <div className="card-header">
                      
                        <img
                       src={(item?.file) ? item?.file : bimg}
                       className="card-img-top"
                       alt="img"
                     />
                      <div className="icon">
                      <FaEdit
                                className="me-2 hd-cr"
                                size={20}
                                onClick={(e) => {
                                  handleShow(item);
                                }}
                              />
                        <FaTrash
                          className="dt-btn hd-cr "
                          size={20}
                          onClick={e => {
                            handleShowDt(item?._id)
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-body " >
                     <h6 style={{ textAlign: "center"}}>{item?.title}</h6>   

                    <p style={{ textAlign: "center", fontSize: "15px" }} className="description font-500 mb-2">

                        <span className="calicon">
                          <i className="ri-calendar-line"></i>
                        </span>
                        {new Date(item.createdAt).toDateString()}
                      </p>
                    
                    </div>
                   
                  </div>
                  </div>

                )
              }
           
            </div>
           
          </>
        }

<div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div>
                 </div>
              </div>
           </div>
        </div>
      </div>
     
 
 <Modal centered scrollable show={showModal} backdrop="static" onHide={()=>{handleClose()}}>
  <Modal.Header closeButton>
    <Modal.Title> {`${selectedId ? 'Edit' : 'Add'} Image`} </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      {selectedId ? 
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
      <Form.Label className="required" column sm="3">
        Title
      </Form.Label>
      <Col sm="8">
        <Form.Control
          type="text"
          placeholder="Title..."
          name='title'
          defaultValue={title}
          isInvalid={!!errors?.title}
          className="form-control "
          readOnly
          onChange={(e) => {
            setTitle(e.target.value);handleChange(e)
          }}
        />
         <Form.Control.Feedback type="invalid">
        {errors?.title}
   </Form.Control.Feedback>
      </Col>
    </Form.Group> :
    <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
    <Form.Label className="required" column sm="3">
      Title
    </Form.Label>
    <Col sm="8">
      <Form.Control
        type="text"
        placeholder="Title..."
        name='title'
        defaultValue={title}
        isInvalid={!!errors?.title}
        className="form-control "
        // readOnly
        onChange={(e) => {
          setTitle(e.target.value);handleChange(e)
        }}
      />
       <Form.Control.Feedback type="invalid">
      {errors?.title}
 </Form.Control.Feedback>
    </Col>
  </Form.Group>
    }
    
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
        <Form.Label className="required" column sm="3">
          Image
        </Form.Label>
        <label id="labimg">
     <img 
      className="imgs" 
      src={imagePreview ?imagePreview : upimg}

      width="100%" alt="" />

      <Form.Control
        type="file"
        name="file"
        className="form-control "
        onChange={(e)=>{onImageChange(e);handleChange(e)}}
        isInvalid={!!errors?.file}
        size="40"
        accept="image/png, image/gif, image/jpeg"
        id="inputds"
      />
        <Form.Control.Feedback type="invalid">
                {errors?.file}
           </Form.Control.Feedback>
        </label>
      </Form.Group>
      

    </Form>
  </Modal.Body>
  <Modal.Footer>
  {loader?<Button variant='primary' className='as_btn' >
        {  progress === 100 ?'Processing...':`Uploading(${progress}%)...`}
      </Button>:<Button variant='primary' className='as_btn' onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}>
      {`${selectedId ? 'Edit' : 'Add'}`}
    </Button>}
    <Button variant="secondary"
    className="as_btn reset-btn" onClick={()=>{handleClose()}}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

      <Modal centered show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static" onHide={handleCloseDt} 
        >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this element?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancle_btn" onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant="primary" className="success_btn" onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

    </>
  )
}


export default PujaPlanSelectionImgMaster;
