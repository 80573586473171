import { useEffect, useState ,useContext} from "react";
import { Button, Modal, Row, Form, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import bimg from "../../src/imgs/default.jpg";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import CropModal from "../components/Shared/CropModal";
import { getBaseURL,setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import upimg from "../imgs/default.jpg"
import { PujaImgGalleyModel } from "../components/Shared/pujaImgGalleryModal";
import { ClockHistory } from "react-bootstrap-icons";


const Banner = () => {
  document.title = 'Banner';
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL)
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId");

  const [offset, setOffset] = useState(0);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [loading, setLoading] = useState();
  const [bannerType, setBannerType] = useState("english");
  const [allData , setAllData] = useState([])
  const [ route , setRoute] = useState("topBanner")
  const [show, setShow] = useState(false);
  const [showdt, setShowDt] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [img, setImg] = useState();
  const [tmBannerType, setTmBannerType] = useState("");
  const [bannerShow, setBannerShow] = useState("");
  const [data, setData] = useState();
  const [errors, setErrors] = useState({});
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [caartifileimage,setFileCaartiimage]=useState()
  const [allDataCount , setAllDataCount] = useState()
  const[loader,setLoader]=useState(false)
  const [ target, setTarget ] = useState("")
  const [ customURL, setCustomURL] = useState("")
  const [ customURLHi, setCustomURLHi] = useState("")
  const [ eventsName,setEventsName ] = useState("")
  const [appBannerImage, setAppBannerImage] = useState("");
  const [appBannerImageHindi, setAppBannerImageHindi] = useState("");
  const [webBannerImage, setWebBannerImage] = useState("");
  const [mobileViewImage, setMobileViewImage] = useState("");
  const [webBannerImageHindi, setWebBannerImageHindi] = useState("");
  const [mobileViewImageHindi, setMobileViewImageHindi] = useState("");

  const [ title, setTitle] = useState("")
  const [ titleHi, setTitleHi] = useState("")
  const [ heading, setHeading] = useState("")
  const [ headingHi, setHeadingHi] = useState("")
  const [ description, setDescription] = useState("")
  const [ descriptionHi, setDescriptionHi] = useState("")
  const [ textAlign,setTextAlign ] = useState("")
  const [ textAlignHi,setTextAlignHi ] = useState("")


  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  // const onImageChange = (event) => {
  //   setBannerShow(event);
  //   setBanner(event);
  // };

  const handleClose = () => {
    setShow(false)
    handleReset()
    setErrors("")
    };

 // Image gallery

    const [modalFieldKey, setModalFieldKey] = useState(null);
    const [showImageGalleyModel, setShowImageGalleyModel] = useState(false);
    const [clearSel, setClearSel] = useState(false);
    const [rindex, setRindex] = useState();
    const [appImage, setAppImage] = useState('');
    const [appImageHindi, setAppImageHindi] = useState('');
    const [webImage, setWebImage] = useState('');
    const [mobileWebImage, setMobileWebImage] = useState('');
    const [webImageHindi, setWebImageHindi] = useState('');
    const [mobileWebImageHindi, setMobileWebImageHindi] = useState('');
    
   
  
    const handleOpenModal = (fieldKey, index) => {
      setClearSel(true);
      setModalFieldKey(fieldKey);
      setRindex(index);
      setShowImageGalleyModel(true);
    };
  
    const extractPaths = url => {
      const extractPath = singleUrl => {
        const parts = singleUrl.split('cloudfront.net/');
        if (parts.length > 1) {
          return parts[1];
        }
        return singleUrl; 
      };
  
      if (Array.isArray(url)) {
        return url.map(singleUrl => extractPath(singleUrl));
      } else if (typeof url === 'string') {
        return extractPath(url);
      }
      return url;
    };
  
    const handleSetImageUrl = (urls, fieldKey) => {
      let extractedPath = extractPaths(urls);
      setClearSel(false);
      if (fieldKey === 'AppBannerImageBanner') {
        setAppBannerImage(urls);
        setAppImage(extractedPath);
        setShowImageGalleyModel(false);
      }
      if (fieldKey === 'AppBannerImageBannerHindi') {
        setAppBannerImageHindi(urls);
        setAppImageHindi(extractedPath);
        setShowImageGalleyModel(false);
      }
      
      if (fieldKey === 'WebBannerImageBanner') {
        setWebBannerImage(urls);
        setWebImage(extractedPath);
        setShowImageGalleyModel(false);
      }
      if (fieldKey === 'WebMobileViewImage') {
        setMobileViewImage(urls);
        setMobileWebImage(extractedPath);
        setShowImageGalleyModel(false);
      }
      if (fieldKey === 'WebBannerImageBannerHindi') {
        setWebBannerImageHindi(urls);
        setWebImageHindi(extractedPath);
        setShowImageGalleyModel(false);
      }
      if (fieldKey === 'WebMobileViewImageHindi') {
        setMobileViewImageHindi(urls);
        setMobileWebImageHindi(extractedPath);
        setShowImageGalleyModel(false);
      }
    };


  const handleCloseDt = () => setShowDt(false);

  const handleShow = async(item) => {
    setShow(true);
    // setBanner("")
    setBannerShow("")
    setSelectedId(item?._id);
    // setTmBannerType(item?.contentEn === true ?"english" : item?.contentHi === true ? "hindi" :"")
    // setImg(item?.file);
    setAppBannerImage(item?.file)
    setWebBannerImage(item?.webfile)
    setMobileViewImage(item?.mobileViewImage)
    setMobileViewImageHindi(item?.mobileViewImageHi)
    setAppBannerImageHindi(item?.appImageHi)
    setWebBannerImageHindi(item?.webImageHi)
    setTarget(item?.action)
    setCustomURL(item?.url)
    setCustomURLHi(item?.urlHi)
    setEventsName(item?.page)
    setTitle(item?.title)
    setTitleHi(item?.titleHi)
    setHeading(item?.heading)
    setHeadingHi(item?.headingHi)
    setDescription(item?.description)
    setDescriptionHi(item?.descriptionHi)
    setTextAlign(item?.textAlign)
    setTextAlignHi(item?.textAlignHi)
  };
  
  const handleShowDt = (id) => {
    setSelectedId(id);
    setShowDt(true);
  };



const fetchData = async () => {
  setLoading(true);
   
  let response = await APIServices.get(`${route}/getAll/${bannerType}/${offset}/${perPage}`, headers);
  let data = response?.response?.data
    
  if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData.Listdata)
        setAllDataCount(apiData.totalCount)
        setLoading(false);
      }
    }else{
      if(data?.status == 4){
        logout()
       }
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage)
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset)
  }

  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};

      if(route === "topBanner"){
        if (!appBannerImage || appBannerImage === "") 
          newErrors.appBannerImage = "Please add App Banner image.";
      }
      if(route === "topBanner"){
        if (!appBannerImageHindi || appBannerImageHindi === "") 
          newErrors.appBannerImageHindi = "Please add App Hindi Banner image.";
      }
      if(route === "middleBanner"){
          if (!webBannerImage || webBannerImage === "") 
          newErrors.webBannerImage = "Please add Web Banner image.";
      }
      if(route === "middleBanner"){
        if (!mobileViewImage || mobileViewImage === "") 
        newErrors.mobileViewImage = "Please add Web Mobile View Banner image.";
      }
      if(route === "middleBanner"){
        if (!webBannerImageHindi || webBannerImageHindi === "") 
        newErrors.webBannerImageHindi = "Please add Web Banner Hindi image.";
      }
      if(route === "middleBanner"){
        if (!mobileViewImageHindi || mobileViewImageHindi === "") 
        newErrors.mobileViewImageHindi = "Please add Web Mobile View Banner image.";
      }
      // if(route === "middleBanner"){
      //   if (!title || title === "") 
      //   newErrors.title = "Please add Title.";

      //   if (!titleHi || titleHi === "") 
      //   newErrors.titleHi = "Please add Title.";

      //   if (!heading || heading === "") 
      //   newErrors.heading = "Please add Heading.";

      //   if (!headingHi || headingHi === "") 
      //   newErrors.headingHi = "Please add Heading.";

      //   if (!description || description === "") 
      //   newErrors.description = "Please add Description.";

      //   if (!descriptionHi || descriptionHi === "") 
      //   newErrors.descriptionHi = "Please add Description.";

      //   if (!textAlign || textAlign === "") 
      //   newErrors.textAlign = "Please add Text Align.";

      //   if (!textAlignHi || textAlignHi === "") 
      //   newErrors.textAlignHi = "Please add Text Align.";
      // }


      // if (!tmBannerType ||tmBannerType === "") 
      // newErrors.imageType = "Please select image type.";
       if(route === "topBanner"){
         if (!target || target === "" ) 
         newErrors.target = "Please select target."; 
       }
       if(target === "outside" || route === "middleBanner"){
        if (!customURL || customURL === ""){
          newErrors.customURL = "Please fill customURL banner."; 
        } 
        if (!customURLHi || customURLHi === ""){
          newErrors.customURLHi = "Please fill CustomURL for hindi banner."; 
        } 
      }

      if( target === "inside" && route === "topBanner") {
        if (!eventsName || eventsName === "") 
        newErrors.eventsName = "Please select Events Name."; 
      }


      return newErrors;
  }; 


  async function handleEdit() {
    const formData = new FormData();
    // if (banner) {
    //   formData.append("file", banner);
    // }

    if(appImage && route === "topBanner"){
       formData.append("file", appImage);
    }
    if(appImageHindi && route === "topBanner"){
      formData.append("appImageHi", appImageHindi);
   }

    if(webImage && route === "middleBanner"){
      formData.append("webfile", webImage);
   }

   if(mobileWebImage && route === "middleBanner"){
    formData.append("mobileViewImage", mobileWebImage);
   }

   if(webImageHindi && route === "middleBanner"){
    formData.append("webImageHi", webImageHindi);
   }

   if(mobileWebImageHindi && route === "middleBanner"){
    formData.append("mobileViewImageHi", mobileWebImageHindi);
   }
   
    // if (tmBannerType === "english") {
    //   formData.append("contentEn", true);
    //   formData.append("contentHi", false);

    // } else if (tmBannerType === "hindi") {
    //   formData.append("contentHi", true);
    //   formData.append("contentEn", false);
    // }
    if(route === "middleBanner"){
      formData.append("action", "outside");
    }else{
      formData.append("action", target);
    }

    if(target === "outside" || route === "middleBanner"){
      formData.append("url", customURL);
      formData.append("urlHi", customURLHi);
      // formData.append("page", "");
    } else if(target === "inside" && route === "topBanner"){
      formData.append("page", eventsName);
      // formData.append("url", "");
    }
    if(route === "middleBanner"){
      formData.append("title", title);
      formData.append("titleHi", titleHi);
      formData.append("heading", heading);
      formData.append("headingHi", headingHi);
      formData.append("description", description);
      formData.append("descriptionHi", descriptionHi);
      formData.append("textAlign", textAlign);
      formData.append("textAlignHi", textAlignHi);
    }

    formData.append("userId", astroUserId);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
     const newErrors = validateInputs();
     setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
      let response = await APIServices.put(
        `${route}/editOne/${selectedId}`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          setLoader(false)
          // setBanner("");
          setShow(false);
          setCropImg();
          updateFile();
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
          
        }
      }
      setLoader(false)
    }
  }

  const handleReset=()=>{
            setAppImage("")
            setWebImage("")
            setTarget("")
            setCustomURL("")
            setCustomURLHi("")
            setEventsName("")
            setTmBannerType("")
            setAppBannerImage("")
            setWebBannerImage("")
            setMobileViewImage("")
            setWebBannerImageHindi('')
            setMobileViewImageHindi('')
            setAppBannerImageHindi('')
            setTitle("")
            setTitleHi("")
            setHeading("")
            setHeadingHi("")
            setDescription("")
            setDescriptionHi("")
            setTextAlign("")
            setTextAlignHi("")
  }

  async function handleCreate() {

    const formData = new FormData();
 if( route === "topBanner"){
   formData.append("file", appImage);
 }

 if(appImageHindi && route === "topBanner"){
    formData.append("appImageHi", appImageHindi);
  }

 if( route === "middleBanner"){
    formData.append("webfile", webImage);
   }
   if( route === "middleBanner"){
    formData.append("mobileViewImage", mobileWebImage);
   }

   if(webImageHindi && route === "middleBanner"){
    formData.append("webImageHi", webImageHindi);
   }
   
   if(mobileWebImageHindi && route === "middleBanner"){
    formData.append("mobileViewImageHi", mobileWebImageHindi);
   }
    // if (tmBannerType === "english") {
    //   formData.append("contentEn", true);

    // } else if (tmBannerType === "hindi") {
    //   formData.append("contentHi", true);
    // }

    // formData.append("action", target);
    if(route === "middleBanner"){
      formData.append("action", "outside");
    }else{
      formData.append("action", target);
    }
   
    if( target === "outside" || route === "middleBanner" ){
      formData.append("url", customURL);
      formData.append("urlHi", customURLHi);
    }
    else if(target === "inside" && route === "topBanner"){
      formData.append("page", eventsName);
    }

    if(route === "middleBanner"){
      formData.append("title", title === undefined || title === "" ? "" : title);
      formData.append("titleHi", titleHi === undefined || titleHi === "" ? "" : titleHi);
      formData.append("heading", heading === undefined || heading === "" ? "" : heading);
      formData.append("headingHi", headingHi === undefined || headingHi === "" ? "" : headingHi);
      formData.append("description", description === undefined || description === "" ? "" : description);
      formData.append("descriptionHi", descriptionHi === undefined || descriptionHi === "" ? "" : descriptionHi);
      formData.append("textAlign", textAlign === undefined || textAlign === "" ? "" : textAlign);
      formData.append("textAlignHi", textAlignHi === undefined || textAlignHi === "" ? "" : textAlignHi);
    }

    formData.append("userId", astroUserId);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
        let response = await APIServices.post(
          `${route}/upload`,
          formData,
          headers
        );
        if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status === true) {
            toast.success(apiData?.message);
            setLoader(false)
            setShow(false);
            handleReset()
            setBannerShow("")
            setCropImg();
            updateFile();
          }else if(apiData?.status === false){
            toast.error(apiData?.message);
            setLoader(false)
          }
        }
        setLoader(false)
      }
  };
  async function updateFile(showToast) {
    let res = await APIServices.get(`${route}/updateFile`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if(showToast){
          toast.success(apiData?.message);
        }
        fetchData();
      }
    }
  }
  const handledelete = async () => {
      let res = await APIServices.delete(`${route}/findByIdAndremove/${selectedId}`, headers);
      if (res?.status === 200) {
        let apiData = res?.data;
        setShowDt(false);
        setOffset(0)
        if (apiData?.status) {
          toast.success(apiData?.message);
          updateFile();
        
        }
      }
   
  };
  const changeRoute = (value) =>{
    setRoute(value)
    setOffset(0)
 
  }

  useEffect(() => {
    fetchData();
  }, [offset,route , bannerType]);
  

// useEffect(()=>{
//   if(caartifileimage){
//     onImageChange(caartifileimage)
//   }
// },[cropimg,caartifileimage])


function handleChange(e) {
  let key, value;
  key = e?.target?.name;
  if (!!errors[key]){
    setErrors({
      ...errors,
      [key]: null,
      });
  }
}

const renderFormModal = () => {
  return(
    <Modal show={show} scrollable={true} size='xl' backdrop="static" onHide={()=>{handleClose(); setCropImg()}}>
    <Modal.Header closeButton>
      <Modal.Title>{`${selectedId ? 'Edit' : 'Add'} Banner`}</Modal.Title>

    </Modal.Header>
    <Modal.Body>
      <Form>
      
              {route === "topBanner" ?
              (
                <Row>
                  <Col sm={6}>
                  <h4 className="mt-3 mb-4">English Banner</h4>
                    <Row>
                      <Col sm={12}>
                        <Form.Group as={Row} className='mb-3' controlId='formImageInput'>
                          <Form.Label className='required' column sm='6'>App Banner Image</Form.Label>
                          <Col sm='6'>
                              <Form.Control
                                type="file"
                                id='upload-photo-1'
                                name='appBannerImage'
                                className='d-none'
                                isInvalid={!!errors?.appBannerImage}
                                onClick={e => {
                                  handleOpenModal('AppBannerImageBanner');
                                  handleChange(e)
                                }}
                              />

                              <img
                                name='appBannerImage'
                                style={{ width: '180px', height: '160px'}}
                                alt='appBannerImage'
                                src={appBannerImage ? appBannerImage : upimg}
                                onClick={e => {
                                  handleOpenModal('AppBannerImageBanner');
                                  handleChange(e)
                                }}
                              />
                            <Form.Control.Feedback type='invalid'>{errors?.appBannerImage}</Form.Control.Feedback>

                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={6}>
                  <h4 className="mt-3 mb-4">Hindi Banner</h4>
                    <Row>
                      <Col sm={12}>
                        <Form.Group as={Row} className='mb-3' controlId='formImageInput'>
                          <Form.Label className='required' column sm='5'>App Banner Image (Hindi)</Form.Label>
                          <Col sm='7'>
                              <Form.Control
                                type="file"
                                id='upload-photo-1'
                                name='appBannerImageHindi'
                                className='d-none'
                                isInvalid={!!errors?.appBannerImageHindi}
                                onClick={e => {
                                  handleOpenModal('AppBannerImageBannerHindi');
                                  handleChange(e)
                                }}
                              />

                              <img
                                name='appBannerImageHindi'
                                style={{ width: '180px', height: '160px'}}
                                alt='appBannerImageHindi'
                                src={appBannerImageHindi ? appBannerImageHindi : upimg}
                                onClick={e => {
                                  handleOpenModal('AppBannerImageBannerHindi');
                                  handleChange(e)
                                }}
                              />
                            <Form.Control.Feedback type='invalid'>{errors?.appBannerImageHindi}</Form.Control.Feedback>

                          </Col>
                        </Form.Group>
                      </Col>
                      
                    </Row>
                  </Col>
                </Row>
              ) :
              (
                 <Row>
                   <Col sm={6}>
                     <h4 className="mt-3 mb-4">English Banner</h4>
                      <Row>
                        <Col sm="12">
                          <Form.Group as={Row} className="mb-3 mt-3" controlId="formTitleInput">
                            <Form.Label className="" column sm="4">
                              Title
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="text"
                                placeholder="Title..."
                                name='title'
                                defaultValue={title}
                                isInvalid={!!errors?.title}
                                className="form-control "
                                onChange={(e) => {setTitle(e.target.value);handleChange(e)}}
                              />
                                {/* <Form.Control.Feedback type="invalid">
                                    {errors?.title}
                                </Form.Control.Feedback> */}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm="12">
                          <Form.Group as={Row} className="mb-3 mt-3" controlId="formTitleInput">
                            <Form.Label className="" column sm="4">
                              Heading
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="text"
                                placeholder="Heading..."
                                name='heading'
                                defaultValue={heading}
                                isInvalid={!!errors?.heading}
                                className="form-control "
                                onChange={(e) => {setHeading(e.target.value);handleChange(e)}}
                              />
                                {/* <Form.Control.Feedback type="invalid">
                                    {errors?.heading}
                                </Form.Control.Feedback> */}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm="12">
                          <Form.Group as={Row} className="mb-3 mt-3" controlId="formTitleInput">
                            <Form.Label className="" column sm="4">
                              Description
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="text"
                                as='textarea'
                                style={{ height: 70 }}
                                placeholder="Description..."
                                name='description'
                                defaultValue={description}
                                isInvalid={!!errors?.description}
                                // className="form-control "
                                onChange={(e) => {setDescription(e.target.value);handleChange(e)}}
                              />
                                {/* <Form.Control.Feedback type="invalid">
                                    {errors?.description}
                                </Form.Control.Feedback> */}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                              <Form.Label className="" column sm="4">
                                Text Align
                              </Form.Label>
                                <Col sm="8">
                                  <Form.Select
                                    aria-label="Default select example"
                                    required
                                    className="form-control hd-cr"
                                    name ="textAlign"
                                    defaultValue={textAlign}
                                    isInvalid={!!errors?.textAlign}
                                    onChange={(e) => {setTextAlign(e.target.value);handleChange(e)}}
                                  >
                                    <option value="">Select Align</option>
                                    <option value="left">Left</option>
                                    <option value="right">Right</option>
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                      {errors?.textAlign}
                                    </Form.Control.Feedback> */}
                                    
                                </Col>
                            </Form.Group> 
                        </Col>
                        <Col sm={12}>
                          <Form.Group as={Row} className='mb-3 mt-3' controlId='formImageInput'>
                            <Form.Label className='required' column sm='4'>Web Banner Image</Form.Label>
                            <Col sm='8'>
                                <Form.Control
                                  type="file"
                                  id='upload-photo-2'
                                  name='webBannerImage'
                                  className='d-none'
                                  isInvalid={!!errors?.webBannerImage}
                                  onClick={e => {
                                    handleOpenModal('WebBannerImageBanner');
                                    handleChange(e)
                                  }}
                                />

                                <img
                                  name='webBannerImage'
                                  style={{ width: '180px', height: '160px'}}
                                  alt='webBannerImage'
                                  src={webBannerImage ? webBannerImage : upimg}
                                  onClick={e => {
                                    handleOpenModal('WebBannerImageBanner');
                                    handleChange(e)
                                  }}
                                />
                              <Form.Control.Feedback type='invalid'>{errors?.webBannerImage}</Form.Control.Feedback>

                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group as={Row} className='mb-3' controlId='formImageInputMobileWeb'>
                            <Form.Label className='required' column sm='4'>Mobile View Image</Form.Label>
                            <Col sm='8'>
                                <Form.Control
                                  type="file"
                                  id='upload-photo-2'
                                  name='mobileViewImage'
                                  className='d-none'
                                  isInvalid={!!errors?.mobileViewImage}
                                  onClick={e => {
                                    handleOpenModal('WebMobileViewImage');
                                    handleChange(e)
                                  }}
                                />

                                <img
                                  name='mobileViewImage'
                                  style={{ width: '180px', height: '160px'}}
                                  alt='mobileViewImage'
                                  src={mobileViewImage ? mobileViewImage : upimg}
                                  onClick={e => {
                                    handleOpenModal('WebMobileViewImage');
                                    handleChange(e)
                                  }}
                                />
                              <Form.Control.Feedback type='invalid'>{errors?.mobileViewImage}</Form.Control.Feedback>

                            </Col>
                          </Form.Group>
                        </Col>
                       
                        
                      </Row> 
                   </Col>
                   <Col sm={6}>
                    <h4 className="mt-3 mb-4">Hindi Banner</h4>
                      <Row>
                      <Col sm="12">
                          <Form.Group as={Row} className="mb-3 mt-3" controlId="formTitleInput">
                            <Form.Label className="" column sm="4">
                              Title
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="text"
                                placeholder="Title..."
                                name='titleHi'
                                defaultValue={titleHi}
                                isInvalid={!!errors?.titleHi}
                                className="form-control "
                                onChange={(e) => {setTitleHi(e.target.value);handleChange(e)}}
                              />
                                {/* <Form.Control.Feedback type="invalid">
                                    {errors?.titleHi}
                                </Form.Control.Feedback> */}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm="12">
                          <Form.Group as={Row} className="mb-3 mt-3" controlId="formTitleInput">
                            <Form.Label className="" column sm="4">
                              Heading
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="text"
                                placeholder="Heading..."
                                name='headingHi'
                                defaultValue={headingHi}
                                isInvalid={!!errors?.headingHi}
                                className="form-control"
                                onChange={(e) => {setHeadingHi(e.target.value);handleChange(e)}}
                              />
                                {/* <Form.Control.Feedback type="invalid">
                                    {errors?.headingHi}
                                </Form.Control.Feedback> */}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm="12">
                          <Form.Group as={Row} className="mb-3 mt-3" controlId="formTitleInput">
                            <Form.Label className="" column sm="4">
                              Description
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="text"
                                as='textarea'
                                style={{ height: 70 }}
                                placeholder="Description..."
                                name='descriptionHi'
                                defaultValue={descriptionHi}
                                isInvalid={!!errors?.descriptionHi}
                                // className="form-control "
                                onChange={(e) => {setDescriptionHi(e.target.value);handleChange(e)}}
                              />
                                {/* <Form.Control.Feedback type="invalid">
                                    {errors?.descriptionHi}
                                </Form.Control.Feedback> */}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                              <Form.Label className="" column sm="4">
                                Text Align
                              </Form.Label>
                                <Col sm="8">
                                  <Form.Select
                                    aria-label="Default select example"
                                    required
                                    className="form-control hd-cr"
                                    name ="textAlignHi"
                                    defaultValue={textAlignHi}
                                    isInvalid={!!errors?.textAlignHi}
                                    onChange={(e) => {setTextAlignHi(e.target.value);handleChange(e)}}
                                  >
                                    <option value="">Select Align</option>
                                    <option value="left">Left</option>
                                    <option value="right">Right</option>
                                  </Form.Select>
                                  {/* <Form.Control.Feedback type="invalid">
                                      {errors?.textAlignHi}
                                    </Form.Control.Feedback> */}
                                    
                                </Col>
                            </Form.Group> 
                        </Col>
                        <Col sm={12}>
                          <Form.Group as={Row} className='mb-3 mt-3' controlId='formImageInput'>
                            <Form.Label className='required' column sm='4'>Web Banner Image Hindi</Form.Label>
                            <Col sm='8'>
                                <Form.Control
                                  type="file"
                                  id='upload-photo-2'
                                  name='webBannerImageHindi'
                                  className='d-none'
                                  isInvalid={!!errors?.webBannerImageHindi}
                                  onClick={e => {
                                    handleOpenModal('WebBannerImageBannerHindi');
                                    handleChange(e)
                                  }}
                                />

                                <img
                                  name='webBannerImageHindi'
                                  style={{ width: '180px', height: '160px'}}
                                  alt='webBannerImageHindi'
                                  src={webBannerImageHindi ? webBannerImageHindi : upimg}
                                  onClick={e => {
                                    handleOpenModal('WebBannerImageBannerHindi');
                                    handleChange(e)
                                  }}
                                />
                              <Form.Control.Feedback type='invalid'>{errors?.webBannerImageHindi}</Form.Control.Feedback>

                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group as={Row} className='mb-3' controlId='formImageInputMobileWeb'>
                            <Form.Label className='required' column sm='4'>Mobile View Image Hindi</Form.Label>
                            <Col sm='8'>
                                <Form.Control
                                  type="file"
                                  id='upload-photo-2'
                                  name='mobileViewImageHindi'
                                  className='d-none'
                                  isInvalid={!!errors?.mobileViewImageHindi}
                                  onClick={e => {
                                    handleOpenModal('WebMobileViewImageHindi');
                                    handleChange(e)
                                  }}
                                />

                                <img
                                  name='mobileViewImageHindi'
                                  style={{ width: '180px', height: '160px'}}
                                  alt='mobileViewImageHindi'
                                  src={mobileViewImageHindi ? mobileViewImageHindi : upimg}
                                  onClick={e => {
                                    handleOpenModal('WebMobileViewImageHindi');
                                    handleChange(e)
                                  }}
                                />
                              <Form.Control.Feedback type='invalid'>{errors?.mobileViewImageHindi}</Form.Control.Feedback>

                            </Col>
                          </Form.Group>
                        </Col>
                        
                      </Row> 
                   </Col>
                      
                      
                      
                 </Row>
              ) 
            }


        {/* <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
             Content Type
          </Form.Label>
          <Col sm="9">
            <Form.Select
              aria-label="Default select example"
              required
              className="form-control hd-cr"
              name ="imageType"
              defaultValue={tmBannerType}
              isInvalid={!!errors?.imageType}
              onChange={(e) => {setTmBannerType(e.target.value);handleChange(e)}}
            >
              <option value="">Choose</option>
              <option value="english">English</option>
              <option value="hindi">Hindi</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors?.imageType}
              </Form.Control.Feedback>
          </Col>
        </Form.Group> */}

    {route === "topBanner" ?  (
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
            Target
          </Form.Label>
          <Col sm="9">
            <Form.Select
              aria-label="Default select example"
              required
              className="form-control hd-cr"
              name ="target"
              defaultValue={target}
              isInvalid={!!errors?.target}
              onChange={(e) => {setTarget(e.target.value);handleChange(e)}}
            >
              <option value="">Choose</option>
              <option value="outside">Outside</option>
              {route === "topBanner" ? <option value="inside">Inside</option> : ""}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors?.target}
              </Form.Control.Feedback>
              
          </Col>
        </Form.Group>
    ) : ""}

              { (target === "outside" || route === "middleBanner") ? (
                <>
                <Row>
                  <Col sm="6">
                      <Form.Group as={Row} className="mb-3 mt-3" controlId="formTitleInput">
                        <Form.Label className="required" column sm={route === "middleBanner" ? "4" : "6"}>
                          URL (English)
                        </Form.Label>
                        <Col sm={route === "middleBanner" ? "8" : "6"}>
                          <Form.Control
                            type="text"
                            placeholder="CustomURL..."
                            name='customURL'
                            defaultValue={customURL}
                            isInvalid={!!errors?.customURL}
                            className="form-control "
                            onChange={(e) => {setCustomURL(e.target.value);handleChange(e)}}
                          />
                            <Form.Control.Feedback type="invalid">
                                {errors?.customURL}
                            </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group as={Row} className="mb-3 mt-3" controlId="formTitleInputHi">
                      <Form.Label className="required" column sm={route === "middleBanner" ? "4" : "6"}>
                        URL (Hindi)
                      </Form.Label>
                      <Col sm={route === "middleBanner" ? "8" : "6"}>
                        <Form.Control
                          type="text"
                          placeholder="CustomURL..."
                          name='customURLHi'
                          defaultValue={customURLHi}
                          isInvalid={!!errors?.customURLHi}
                          className="form-control "
                          onChange={(e) => {setCustomURLHi(e.target.value);handleChange(e)}}
                        />
                          <Form.Control.Feedback type="invalid">
                              {errors?.customURLHi}
                          </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                </>
              )
              : ""
            }

            {target === "inside" && route === "topBanner" &&
              <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                <Form.Label className="required" column sm="3">
                  Page/Screen  
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    aria-label="Default select example"
                    required
                    className="form-control hd-cr"
                    name ="eventsName"
                    defaultValue={eventsName}
                    isInvalid={!!errors?.eventsName}
                    onChange={(e) => {setEventsName(e.target.value);handleChange(e)}}
                  >
                    <option value="">Choose</option>
                    <option value="generate_kundli">Generate Kundli</option>
                    <option value="panchang">Panchang</option>
                    <option value="puja_list">Puja List</option>
                    <option value="astrologers_list">Astrologers List</option>
                    <option value="chadawa_list">Chadawa List</option>
                    <option value="ask_a_question">Ask a Question</option>
                    <option value="mandir">Mandir</option>
                    <option value="recharge">Recharge</option>
                    <option value="manokamna">Manokamna</option>
                    <option value="live_darshan">Live Darshan</option>
                    <option value="festivals_list">Festivals List</option>
                    <option value="short_stories_list">Short Stories List</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                      {errors?.eventsName}
                    </Form.Control.Feedback>
                    
                </Col>
            </Form.Group> 
            }
            
      </Form>
    </Modal.Body>
    <Modal.Footer>
   {loader?<Button
      variant="primary"
      className="as_btn"
    >
       {`${selectedId ? 'Processing...' : 'Processing...'}`}
    </Button>: <Button
    variant="primary"
    className="as_btn"
    onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}>
          {`${selectedId ? 'Edit' : 'Add'}`}
  </Button>}
      <Button variant="secondary" className="as_btn" onClick={()=>{handleClose(); setCropImg()}}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
    
  )

}

  return (
    <>
      <div className="main aarti_section">
        <div className="gita_quote_heading">
          <div className="container d-flex align-items-center justify-content-between">
            <h4 className="hd-list-f left-q">Manage Banners <small>({allDataCount})</small></h4>
            <div className="_btn">
              {/* <Button
                variant="primary"
                className="by-btn as_btn"
                onClick={()=>updateFile(true)}
              >
                Update File
              </Button> */}
            
              <Button
                variant="primary"
                onClick={handleShow}
                className="by-btn as_btn me-0"
              >
              {route === "middleBanner"? "Web Banner":" App Banner"}
              </Button>
            </div>
          </div>
        </div>
        <div className="aarti_inner pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <Sidebar />
              </div>
              <div className="col-md-8 col-lg-9">
                {loading ? (
                  <div className="center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="hd-list-f left-q">

                          <span 
                          className={route === "topBanner"?"left-q hd-cr borderRoute":"left-q hd-cr" }
                          onClick={(e)=>changeRoute("topBanner")}> App Banner</span>
                          <span className={route === "middleBanner"?"left-q hd-cr borderRoute":"left-q hd-cr" }
                          onClick={(e)=>changeRoute("middleBanner")}>Web Banner</span> 
                          </h6>

                        {/* <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                            value={bannerType}
                            onChange={(e) => {setBannerType(e.target.value); setOffset(0)}}
                          >
                            <option value="english">English</option>
                            <option value="hindi">Hindi</option>
                          </Form.Select> */}
                          
                        </div>
                      </div>
                    </div>

                
                    <div className="row mt-3">

                    { data?.map((item)=> 
                    <div className="col-md-6 col-lg-4">
                    <div className="card mb-4" key={item._id}>
                      <div className="card-header">
                        <img
                          // crossOrigin="anonymous"
                          src={(item?.file) ? item?.file : item?.webfile ? item?.webfile : bimg}
                          className="card-img-top"
                          alt="img"
                        />
                        <div className="icon">
                          <FaEdit
                                    className="me-2 hd-cr"
                                    size={20}
                                    onClick={(e) => {
                                      handleShow(item);
                                    }}
                                  />
                          <FaTrash
                            className="dt-btn hd-cr"
                            size={20}
                            onClick={(e) => {
                              handleShowDt(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                    )}
                    </div>
                  </>
                )}
                 <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderFormModal()}

      <Modal
        show={showdt}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="success_btn"
            onClick={handledelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <PujaImgGalleyModel
          id='ImgGalleyModel'
          show={showImageGalleyModel}
          onHide={() => setShowImageGalleyModel(false)}
          modalHeading={'ImgGalley'}
          size='lg'
          handleSetImageUrl={handleSetImageUrl}
          fieldKey={modalFieldKey}
          setClearSel={setClearSel}
          clearSel={clearSel}
        />
      <ToastContainer />
      <CropModal 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    caartifileimage={caartifileimage}
    setFileCaartiimage={setFileCaartiimage}/>
    </>
  );
};

export default Banner;
