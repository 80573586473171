import React, { useEffect, useState, useMemo,useContext, useCallback } from 'react';
import { Button, Container, Modal ,Form } from 'react-bootstrap';
import { useNavigate, useParams , useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import { FaEdit, FaTrash, FaBell,FaArrowLeft, FaSistrix } from 'react-icons/fa';
import Papa from 'papaparse';
import APIServicenew from '../../utils/APIGeneralService';
import { UserContext } from '../../context/UserContextProvider';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import BookingModal from '../../components/SharedPooja/BookingModal';
import PujaBookingStatus from '../../components/SharedPooja/PujaBookingStatus';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import PujaExpensesModal from '../../components/SharedPooja/pujaExpensesModal';
import SelectPanditModule from '../../components/SharedPooja/selectPanditModule';

const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
const APIServicesSec = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);
const url = process.env.REACT_APP_WEBSITE_URL
const cookies = new Cookies();
const token = cookies.get('accessToken');
const headers = { Authorization: `Bearer ${token}` };
const astroUserId = cookies.get('astroUserId');

const ManagePuja = () => {
  // const {id : pujaId } = useParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const pujaId = searchParams.get('pujaId');
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search , setSearch] = useState('')
  const [status ,setStatus] = useState('Paid')
  const [country ,setCountry] = useState('')
  const [paidCount ,setPaidCount] = useState()
  const [prasadAdd, setPrasadAdd] = useState("")
  const [dakshinaAdd, setDakshinaAdd] = useState("")
  const [pujaData, setPujaData] = useState();
  const [pujaBookings, setPujaBookings] = useState();
  const [pujaBookingstatus, setPujaBookingStatus] = useState(); // for state use puja status start to end
  const [pujasAllBookings, setPujaAllBookings] = useState();
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [total,setTotal]=useState()
  const [addbookinghide,setAddBookingHide]=useState(false)
  const [show, setShow] = useState(false);
  const [memberdata, setMemberData] = useState();
  const [totalBookingPrice ,setTotalBookingPrice] = useState(null)
  const [totalBookingPaidCount ,setTotalBookingPaidCount] = useState(null)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchPujaDetails = async () => {


    let [pujaDetailsRes, pujaBookingsRes] = await Promise.all([
      APIServices.get(`astroPooja/getDetailById/${pujaId}`, headers),
      APIServices.get(`pujaBooking/getByPujaId/${pujaId}`, headers),
    ]);
    if (pujaDetailsRes?.status === 200) {
      let apiData = pujaDetailsRes?.data;
      if (apiData?.status) {
        setPujaData(apiData?.data);
      }
    }
    if (pujaBookingsRes?.status === 200) {
      let bookingApiData = pujaBookingsRes?.data?.bookings;
      setPujaBookings(bookingApiData);
      setPujaBookingStatus(bookingApiData)
      
    }
  };

  const refetchPujaDetails = async (req, res) => {
    let [pujaDetailsRes] = await Promise.all([APIServices.get(`astroPooja/getDetailById/${pujaId}`, headers)]);
    if (pujaDetailsRes?.status === 200) {
      let apiData = pujaDetailsRes?.data;
      if (apiData?.status) {
        setPujaData(apiData?.data);
      }
    }
  };

  const pujaBookingTotalPrice = async (req, res) => {
    let pujaBookingTotal = await APIServices.get(`astroPooja/pujaBookingTotalPrice/${pujaId}`, headers);
    if (pujaBookingTotal?.status === 200) {
      if (pujaBookingTotal?.data?.totalPriceOfPujaBookings) {
        setTotalBookingPrice(pujaBookingTotal?.data?.totalPriceOfPujaBookings)
        setTotalBookingPaidCount(pujaBookingTotal?.data?.totalBookingPaidCount)
      }else{
        console.log("Somthing went wrong");
        
      }
    }
  };

  useEffect(()=>{
 if (pujaId) {
  fetchPujaDetails();
  pujaBookingTotalPrice()
 }
},[pujaId])

  useEffect(() => {
  fetchAllBookingsbyId()
  // if (!pujaId) return;
  // fetchPujaDetails();
  }
, [status ,country , prasadAdd, dakshinaAdd]);
  useEffect(() =>{
  fetchBookingsByPagination(skip, limit, pageIndex ,status,search ,country , prasadAdd, dakshinaAdd)
  },[skip, limit, pageIndex,status,search ,country, prasadAdd, dakshinaAdd]) 


  const [totalAmount,setTotalAmount] = useState('')
  const fetchBookingsByPagination = useCallback(
    async (skip, limit, index) => {
      try {
        const response = await APIServices.get(
          `pujaBooking/getByPujaIdFilter/${pujaId}?status=${status}&skip=${skip}&limit=${limit}&searchKey=${search}&country=${country}&prasad_add=${prasadAdd}&dakshina_add=${dakshinaAdd}`,
          headers
        );

        if (response.status === 200) {
          setPageCount(Math.ceil(response.data?.resCount / limit));
          setPujaBookings(response.data?.bookings);
          setTotal( response?.data?.total)
          setPaidCount(response?.data?.paidCount)
          // window.scrollTo({ top: 0 });
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex ,status ,search ,country, prasadAdd, dakshinaAdd]
  );


  const fetchAllBookingsbyId = async () => {
      try {
        const response = await APIServices.get(
          `pujaBooking/getByPujaIdFilter/${pujaId}?status=${status}&country=${country}&prasad_add=${prasadAdd}&dakshina_add=${dakshinaAdd}`,
          headers
        );

        if (response.status === 200) {
          setPujaAllBookings(response.data?.bookings);
          setTotal( response?.data?.total)
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      
    }
  

  const handleBookingEdit = async row => {
    setSelectedBooking(row);
    setShowBookingModal(true);
  };

  const handleDeleteClick = async row => {
    setSelectedBooking(row);
    setShowDeleteModal(true);
  
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedBooking(null);
    fetchAllBookingsbyId()

  };
 
  const handleNotificationClick = async(row) =>{
  if (pujaData) {
      const payload = { type: "new_puja_booking_temp", name: row?.name, contactNumber: row?.contactNumber, date:pujaData?.date ,place:pujaData?.address,pujaName:pujaData?.hindiTitle};
      let response = await APIServicesSec.post('whatsAppApi/sendPoojaBookingNoti', payload, headers);
      if (response && response.status === 200) {
        toast.success(response?.data?.message);
        
      }else{
        toast.error(response?.data?.message ? response?.data?.message : 'Something went wrong');
      }
    }
 
 }


  const handleBookingDelete = async () => {
    try {
      if (!selectedBooking) return;
      const id = selectedBooking._id;

      const deleteRes = await APIServices.delete(`pujaBooking/${pujaId}`, headers);

      if (deleteRes && deleteRes.status === 200) {
        toast.success(deleteRes?.data?.message);
        fetchBookingsByPagination(skip, limit, pageIndex);
      } else {
        toast.error(deleteRes?.data?.message ? deleteRes?.data?.message : 'Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message ? error?.message : 'Something went wrong');
    } finally {
      setSelectedBooking(null);
      setShowDeleteModal(false);
    }
  };

  const bookingColumns = useMemo(
    () => [
      {
        Header: 'NO.',
        Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
      },
      {
        Header: 'Gotra',
        accessor: 'gotra',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Address',
        // accessor: 'address',
        accessor: row => {
          return (
            <span>
              {row?.address} {row?.city ? ","+`${row?.city}` : ""}{row?.state ? ","+`${row?.state}` : ""}
              {row?.country ? ","+`${row?.country}` : ""}{row?.postalCode ? ","+`${row?.postalCode}` : ""}
            </span>
          );
        },
      },
      {
        Header: 'WhatsApp Number',
        accessor: 'maskcontactNumber',
      },
    
      {
        Header: 'Puja Plan',
        accessor: row => {
          return (
            <span>
              {row?.pujaPlan?.title} -- {row?.currencySymbol}{row?.pujaPlan?.price}
            </span>
          );
        },
      },
      {
        Header: 'Source',
        accessor: row => {
          return (
            <span>
              {row?.type ? row?.type:'-'}
            </span>
          );
        },
      },
      {
        Header: 'Members',
        accessor: row => {
          return (<>
            <span>
              {row?.members?.length}
            </span>
            <br/>
            <div onClick={() => { handleShow(); setMemberData(row); }} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                 View
            </div>
             
              </>
          );
        },
      },
      {
        Header: 'Payment Status',
        accessor: row => {
          return (
            <span>
              {row?.paymentStatus === 'Paid' ? <span className='paid-option'>Paid</span> : <span className='text-pending fw-bold'>{row?.paymentStatus}</span>}
            </span>
          );
        },
      },
      {
        Header: 'Prasad Status',
        accessor: row => {
          return (
            <span>
              {row?.prasad_add ? (row?.prasad_add === "no" ? "no" : row?.prasad_price === 0 ? 0 : row?.currencySymbol+row?.prasad_price) : "_"}
            </span>
          );
        },
      },
      {
        Header: 'Dakshina Status',
        accessor: row => {
          return (
            <span>
              {row?.dakshina_add ? (row?.dakshina_add === "no" ? "no" : row?.dakshina_price === 0 ? 0 : row?.currencySymbol+row?.dakshina_price) : "_" }
            </span>
          );
        },
      },
      {
        Header: 'Date',
        accessor: row => {
          return <span>
            {/* {new Date(row.updatedAt).toLocaleDateString()} */}
            {row.updatedAt ? moment(row.updatedAt).format('DD MMM, YYYY hh:mm A') : '_'}
            </span>;
        },
      },
      {
        Header: 'Actions',
       accessor: row => {
          return (<>
            <span>
              {!addbookinghide?<>
              <FaEdit
                className='me-2 hd-cr'
                size={20}
                title={'Edit'}
                color='#000'
                onClick={() => {
                  handleBookingEdit(row);
                }}
              />

              {row?.paymentStatus === "Paid"&&
              <FaBell
              className='me-2 hd-cr'
              size={20}
              color='#277a1d'
              title='Resend Booking Notification'
              onClick={() => 
                handleNotificationClick(row)
              }
              />}
              </>:""}
              {/* <FaTrash className='dt-btn me-2 hd-cr' size={20} title='Delete' onClick={() => handleDeleteClick(row)} /> */}
            </span></>
          );
        },
      },
    ],
    [pujaData,addbookinghide]
  );

  const handleBookingSubmit = async data => {
    try {

      delete data?.maskcontactNumber;

      let memebersData = [];
      if (data?.memberName && data?.memberName.length > 0) {
        data?.memberName.forEach((name, i) => {
          memebersData[i] = {
            name: name,
            gotra: data?.memberGotra?.[i],
          };
        });

        delete data?.memberName;
        delete data?.memberGotra;
      }else{
        memebersData= [{
          name: data?.name,
          gotra: data?.gotra,
        }];
      }
      
      let res;
      if (!selectedBooking) {
        const payload = { ...data, total_price : totalAmount, city : data?.city, state : data?.state, country : data?.country, postalCode : data?.postalCode,address2 : data?.address2, members: memebersData, pujaId:pujaId, prasad_add : data?.prasad_add?.value, dakshina_add: data?.dakshina_add?.value, prasad_price: data?.prasad_price, dakshina_price: data?.dakshina_price ,pujaPlan: data?.pujaPlan?.value, contactNumber:data.phoneCode.replace("+", "")+data.contactNumber, adminId: astroUserId,type:"Admin",paymentStatus:"Paid" };
        res = await APIServices.post('pujaBooking', payload, headers);
      } else {
        const payload = { ...data,total_price:totalAmount, city : data?.city,state : data?.state, country : data?.country,postalCode : data?.postalCode,address2 : data?.address2, members: memebersData, pujaId:pujaId,paymentStatus: data?.paymentStatus?.value, prasad_add : data?.prasad_add?.value, dakshina_add : data?.dakshina_add?.value, prasad_price: data?.prasad_add?.value === "yes" ? data?.prasad_price : null, dakshina_price: data?.dakshina_add?.value === "yes" ? data?.dakshina_price : null,pujaPlan: data?.pujaPlan?.value, adminId: astroUserId };
        res = await APIServices.put(`pujaBooking/editBooking/${selectedBooking._id}`, payload, headers);
      }

    

      if (res && res.status === 200) {
        toast.success(res?.data?.message);
        fetchBookingsByPagination(skip, limit, pageIndex);
        handleBookingModalClose();
        fetchPujaDetails()

      // if (!selectedBooking) {
      //   var data = res?.data.booking
      // const payloadSec = {type: "new_puja_booking_temp", name: data?.name, contactNumber: data?.contactNumber, date:pujaData?.date ,place:pujaData?.address,pujaName:pujaData?.hindiTitle};
      // let response = await APIServicesSec.post('whatsAppApi/sendPoojaBookingNoti', payloadSec, headers);
      // if (response && response.status === 200) {
      //   toast.success(response?.data?.message);
      // }else{
      //   toast.error(response?.data?.message ? response?.data?.message : 'Something went wrong');
      // }
      // }
        setSelectedBooking(false);
      } else {
        toast.error(res?.data?.message ? res?.data?.message : 'Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message ? error?.message : 'Something went wrong');
    } finally {
    }
  };

  const handleBookingModalClose = () => {
    setShowBookingModal(false);
    setSelectedBooking(null);
  };


  async function updateFile(showToast) {
    let res = await APIServices.get(`astroPooja/updateFile`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
        toast.success(apiData?.message);
        }
        
      }
    }
  }

  useEffect(()=>{
    updateFile()
  },[addbookinghide])

  const handleDownload = () => {

    for(let puja of pujasAllBookings){

      if (puja.name){
        puja['Name']=puja.name
      }else{
        puja['Name'] = "-";
      }
      if (puja.gotra){
        puja['Gotra']=puja.gotra
      }else{
        puja['Gotra'] = "-";
      }
      if (puja.address){
        puja['Address']=puja.address
      }else{
        puja['Address'] = "-";
      }
        puja['Contact Number']=`${puja.maskcontactNumber}`
      if(puja.pujaPlan){
        // puja['Price'] = puja?.pujaPlan?.price ?? 0;
        puja['Title'] = puja?.pujaPlan.title ?? ''
      }
      if(puja.currency==="INR" && puja.pujaPlan){
        puja['Price ']=  `₹ ${puja?.pujaPlan?.price} ` ?? 0

      }else{
        puja['Price ']=  `$ ${puja?.pujaPlan?.price} `?? 0
      }
      if(puja.type){
        puja['Source']=puja.type
      }
      if(puja?.members?.length > 0){
        let nameStr = ''
        for(let userName of puja.members){
          if(userName?.name){
            nameStr += `${userName.name},`
          }
        }

        puja['Members Name'] = nameStr?.replace(/,*$/, '');

      }

      if(puja.paymentStatus){
        puja['Payment Status']=puja.paymentStatus
      }
      if (puja.prasad_add) {
        puja['Prasad Status'] = 
        puja?.prasad_add === 'no'
          ? 'no'
          : puja?.prasad_price === 0
          ? 0
          : puja?.prasad_price
      }else{
        puja['Prasad Status'] = "-"
      }
      if (puja.dakshina_add) {
        puja['Dakshina Status'] = 
        puja?.dakshina_add === 'no'
              ? 'no'
              : puja?.dakshina_price === 0
              ? 0
              : puja?.dakshina_price
      }else{
        puja['Dakshina Status'] = "-"
      }

      if(puja.createdAt){
        puja['Booking date ']=moment(puja?.createdAt).format('DD MMM, YYYY hh.mm A')
      }
    

      // if(puja.currency==="INR"){
      //   puja['Contact Number']=`91${puja.contactNumber}`
      // }else{
      //   puja['Contact Number']=`1${puja.contactNumber}`
      // }

    
  
    
      delete puja.pujaPlan
      delete puja?.total_price;
      delete puja?.dakshina_add;
      delete puja?.dakshina_price;
      delete puja?.prasad_add;
      delete puja?.prasad_price;
      delete puja?.remark;
      delete puja?.responderId;
      delete puja?.TXN_ID;
      delete puja?.adminId;
      delete puja?.joiningLink;
      delete puja?.maskcontactNumber;
      delete puja?.members;
      delete puja?.isDeleted;
      delete puja?.__v;
      delete puja?.userId;
      delete puja?.pujaId;
      delete puja?.updatedAt;
      delete puja.createdAt;
      delete puja.price;
      delete puja.status;
      delete puja.name;
      delete puja.gotra;
      delete puja.address;
      delete puja._id;
      delete puja.paymentStatus;
      delete puja.contactNumber;
      delete puja.type;
      delete puja?.countryId;
      delete puja?.currency;
      delete puja?.currencySymbol;
      delete puja?.email;
      


    }

    

    let csv = Papa.unparse(pujasAllBookings, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveBlob(csvData);
    else {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
      a.setAttribute('download', 'your_data.csv');
      document.body.appendChild(a);
      a.download = 'BookingList.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

// for puja Expenses

  const [showPujaExpense,setShowPujaExpense] = useState(false)
  const [selectedExpense, setSelectedExpense] = useState()

const handlePujaExpensesEdit = async row => {
    setSelectedExpense(row);
    setShowPujaExpense(true);
  };

    const handlePujaExpensesModalClose = () => {
    setShowPujaExpense(false);
    setSelectedExpense(null);
  };

// for puja pandit

const [showPujaPandit,setShowPujaPandit] = useState(false)
const [selectedPanditExpense, setSelectedPanditExpense] = useState()

const handlePujaPanditEdit = async row => {
  setSelectedPanditExpense(row);
  setShowPujaPandit(true);
};

  const handlePujaPanditModalClose = () => {
    setShowPujaPandit(false);
    setSelectedPanditExpense(null);
};

function copy(text) {
  navigator.clipboard.writeText(text);
  toast.info('copied');
}

  return (
    <div>
      <div className='page_heading'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
              <div className='col-7'>
              <div className='overflow-x-auto'>
                <h5 className='fw-bold hd-list-f text-nowrap'>
                <FaArrowLeft
                      className="hd-cr "
                      style={{marginRight: "15px"}}
                      size={25}
                      onClick={() => navigate(-1)}/>
                
                  {pujaData?.title}
                </h5>
              </div>
              </div>
              <div className='col-5'>
              <div className='_btn d-flex align-items-center'>
                <Button
                  className='by-btn as_btn mt-0'
                  variant='primary'
                  onClick={() => {
                    setShowPujaPandit(true);
                  }}
                >
                  {(pujaData?.pujaExpencese?.panditName || pujaData?.pujaPandit !==null) ? "View Pandit" : "Puja Pandit"}
                </Button>
                <Button
                  className='by-btn as_btn mt-0'
                  variant='primary'
                  onClick={() => {pujaData?.pujaPandit === null ?
                    setShowPujaPandit(true):
                    setShowPujaExpense(true);
                  }}
                >
                  {(pujaData?.pujaExpencese?.expenceseItem.length > 0 && pujaData?.pujaExpencese?.panditPrice !== null) ? 'View Expense' : 'Puja Expense'}
                </Button>
                {!addbookinghide?
                    <Button
                      className='by-btn as_btn me-0 mt-0'
                      variant='primary'
                      onClick={() => {
                        setShowBookingModal(true);
                      }}
                    >
                      Add puja booking
                    </Button>
                  :""}
              </div>
              </div>
           
          </div>
        </div>
      </div>
      <Container className='mt-4 box-shadow br-6 p-20 my-3'>
        <div className='d-flex justify-content-between'>
          <div>
              <h6>Date: {moment(pujaData?.pujaDate).format(("DD MMM, YYYY "))}</h6>
              <h6>Location: {pujaData?.address}</h6>
          </div>
          <div>
                <Button
                  className='by-btn as_btn mt-0'
                  variant='primary'
                    onClick={() => copy(pujaData?.slug ? `${url}puja-feedback/${pujaData?.slug}` : "")}
                  >Copy english feedback url
                </Button>
                <Button
                  className='by-btn as_btn mt-0'
                  variant='primary'
                  onClick={() => copy(pujaData?.slug ? `${url}hi/puja-feedback/${pujaData?.slug}` : "")}
                 >Copy hindi feedback url
                </Button>
          </div>
        </div>
        <hr />
        <h5 className='fw-bold'>
          <u>Puja Status</u>
        </h5>
        <PujaBookingStatus pujaId={pujaId} pujaData={pujaData} paidCount={paidCount} refetchPujaDetails={refetchPujaDetails} pujaBookings={pujaBookingstatus}  setAddBookingHide={setAddBookingHide} />
      </Container>
      <hr />
      <Container className='mt-4 mb-3'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h5 className='fw-bold ms-2 mb-0'>
          <u>Total Bookings <small>({total})</small> Paid<small>({paidCount})</small></u>
        </h5>
        {adminUser?.adminType === "super_admin" ? 

          (<Button
          className='as_btn ms-2 mt-0 download-btn'
          variant='primary'
          disabled={!pujaBookings || (pujaBookings && pujaBookings.length <= 0)}
          onClick={() => {
            handleDownload();
          }}
        >
          Download File
        </Button>) : "" }
        </div>
      <div className='d-flex'>
      <div className='chat-search-field w-100'>
              <span className='chat-bot-search-icon'>
                {/* <i className='fa fa-search'></i> */}
                <FaSistrix />
              </span>
              <input
                type='text'
                className='form-control form-input w-75'
                placeholder='Search by name and contact no.'
                onChange={ (e) => setSearch(e.target.value)}
                value={search}
              />
      </div>
         <Form.Select
              aria-label="categories"
              className=" w-25 astroWatch-select-hover hd-cr right-q"
              value={country}
              onChange={(e) => {setCountry(e.target.value);setSkip(0);setPageIndex(0)}}
            >
              <option value=''>country</option>
              <option value="USD" >USA</option>
              <option value= "INR">India</option>

         </Form.Select>
         <Form.Select
              aria-label="categories"
              className=" w-25 astroWatch-select-hover hd-cr right-q"
              value={status}
              onChange={(e) => {setStatus(e.target.value);setSkip(0);setPageIndex(0)}}
            >
              <option value="">All</option>
              <option value="Paid">Paid</option>
              <option value="Pending" >Pending</option>
              <option value="Failed" >Failed</option>
              {/* <option value= "Failed">Failed</option> */}

         </Form.Select>
         
         <Form.Select
              aria-label="categories"
              className=" w-25 astroWatch-select-hover hd-cr right-q"
              value={prasadAdd}
              onChange={(e) => {setPrasadAdd(e.target.value);setSkip(0);setPageIndex(0)}}
            >
              <option value="">Prasad Add</option>
              <option value="yes">Yes</option>
              <option value="no" >No</option>

         </Form.Select>
         
         <Form.Select
              aria-label="categories"
              className=" w-25 astroWatch-select-hover hd-cr right-q"
              value={dakshinaAdd}
              onChange={(e) => {setDakshinaAdd(e.target.value);setSkip(0);setPageIndex(0)}}
            >
              <option value="">Dakshina Add</option>
              <option value="yes">Yes</option>
              <option value="no" >No</option>

         </Form.Select>
      </div>
      </Container>
        {pujaBookings && pujaBookings.length > 0 ?
          <DynamicDataTableWithCount
            columns={bookingColumns}
            data={pujaBookings}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={fetchBookingsByPagination}
            styleClass='puja_bookings_table'
            forcePage={skip/limit}
          />
      :<div className='text-center mt-4 mb-4'><h6>No record available</h6></div>  }
      <BookingModal
        show={showBookingModal}
        handleClose={() => handleBookingModalClose()}
        pujaData={pujaData}
        onSubmit={handleBookingSubmit}
        selectedBooking={selectedBooking}
        totalAmount={totalAmount}
        setTotalAmount={setTotalAmount}
      />
       <PujaExpensesModal
        show={showPujaExpense}
        handleClose={() => handlePujaExpensesModalClose()}
        pujaData={pujaData}
        pujaId={pujaId}
        adminUser={adminUser?._id}
        selectedExpense={selectedExpense}
        fetchPujaDetails={fetchPujaDetails}
        totalBookingPrice={totalBookingPrice}
        totalBookingPaidCount={totalBookingPaidCount}
      />
      <SelectPanditModule
        show={showPujaPandit}
        handleClose={() => handlePujaPanditModalClose()}
        pujaData={pujaData}
        pujaId={pujaId}
        adminUser={adminUser?._id}
        selectedPanditExpense={selectedPanditExpense}
        fetchPujaDetails={fetchPujaDetails}

      />
      
      
      <Modal
      centered
      scrollable
        show={showDeleteModal}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDeleteModal}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Puja Booking?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handleBookingDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose} scrollable={true} centered>
        <Modal.Header closeButton>
          <Modal.Title>Members Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Puja Name:</div>
            <div className="col-6">{memberdata?.pujaPlan?.title}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Gotra:</div>
            <div className="col-6">{memberdata?.gotra}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Address:</div>
            <div className="col-6">{memberdata?.address}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Address 2:</div>
            <div className="col-6">{memberdata?.address2}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Phone No.:</div>
            <div className="col-6">{memberdata?.maskcontactNumber}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Source:</div>
            <div className="col-6">{memberdata?.type}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">City:</div>
            <div className="col-6">{memberdata?.city}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">State:</div>
            <div className="col-6">{memberdata?.state}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Country:</div>
            <div className="col-6">{memberdata?.country}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Postal Code:</div>
            <div className="col-6">{memberdata?.postalCode}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Member Name:</div>
            <div className="col-6">
            {memberdata?.members?.map((item,index)=>{
            return(<><div>
           {index+1}.{item?.name}
           </div>
            </>)
        })}
        </div>
          </div>
         
        {/* <Table >
      <thead>
        <tr>
          <th>No.</th>
          <th>Member Name</th>
          <th>Puja Name</th>
          <th>Source</th>
          <th>Gotra</th>
          <th>Address</th>
          <th>Phone No.</th>
        </tr>
      </thead>
      <tbody>
      {memberdata?.members?.map((item,index)=>{
return(<>
 <tr>
          <td>{index+1}</td>
          <td>{item?.name}</td>
          <td>{memberdata?.pujaPlan?.title}</td>
          <td>{memberdata?.type}</td>
          <td>{memberdata?.gotra}</td>
          <td>{memberdata?.address}</td>
          <td>{memberdata?.contactNumber}</td>
        </tr>
        </>)
        })}
        
      </tbody>
    </Table> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='as_btn reset-btn' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default ManagePuja;
